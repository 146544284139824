import { CommandList, CommandLoading } from "cmdk";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { cn } from "@/lib/utils";

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "../ui/command";

import { apiClient, getSession } from "@/api/utils";
import { useAppStore } from "@/stores/AppStore";
import { LegalEntity } from "@/api/legal-entities";

const LegalEntitySelector = () => {
  const { user, legalEntity, setLegalEntity } = useAppStore();
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: ["legalEntities", user?.id],
    queryFn: async () => {
      const { token } = await getSession();
      const { data } = await apiClient.get(
        `/users/legal-entity/${user?.documentNumber}`,
        {
          headers: {
            Authorization: token,
          },
        },
      );
      return data as LegalEntity[];
    },
  });

  const legalEntities = query.data ?? [];

  return (
    <Command>
      <CommandInput placeholder="Buscar empresa..." />

      {!query.isLoading && legalEntities.length === 0 && (
        <CommandEmpty>Sin empresas encontradas</CommandEmpty>
      )}

      <CommandList>
        {query.isLoading && (
          <CommandLoading className="py-2 text-center">
            Cargando...
          </CommandLoading>
        )}

        <CommandGroup>
          {legalEntities.map((item) => (
            <CommandItem
              className="flex cursor-pointer flex-row items-center justify-between"
              key={item.id.toString()}
              value={item.name}
              onSelect={() => {
                setLegalEntity(item);
                queryClient.invalidateQueries(["getDocuments"]);
              }}
            >
              <div className="flex flex-row items-center">
                <span
                  className={cn([
                    "mr-2 inline-block h-2 w-2 rounded-full",
                    legalEntity?.documentNumber === item.documentNumber
                      ? "bg-foreground"
                      : "bg-transparent",
                  ])}
                ></span>
                <span className="mr-3 max-w-[180px] truncate">{item.name}</span>
              </div>
              <div>{item.documentNumber}</div>
            </CommandItem>
          ))}
        </CommandGroup>
      </CommandList>
    </Command>
  );
};

export { LegalEntitySelector };
