import { SigneeRepresentation } from "../types/Signee.type";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

export type NewTransactionSignee = {
  documentNumber: string;
  firstName: string;
  lastName: string;
  maternalLastName?: string | null;
  phoneNumber?: string;
  email: string;
  country: string;
  inRepresentationOf?: SigneeRepresentation;
};

export type NewTransactionAttachment = {
  id: string;
  name: string;
  code: string;
  size: number;
  contentType: string;
  subcategory: string;
  contents: File;
};

export type NewTransactionDocument = {
  id: string;
  documentId?: string;
  documentCode?: string;
  title: string;
  filename: string;
  size: number;
  subcategory: string | null;
  category: string | null;
  description: string | null;
  signees: NewTransactionSignee[];
  attachments: NewTransactionAttachment[];
  contents: File;
  isProtocolized: boolean;
  procedureType: string;
};

export type NewTransactionNotary = {
  id: string;
  name: string;
};

export interface ITransactionStore {
  id?: string;
  documents: NewTransactionDocument[];
  notary: NewTransactionNotary | null;

  reset: () => void;
  setId: (id: string) => void;
  setNotary: (notary?: NewTransactionNotary) => void;

  addDocument: (document: NewTransactionDocument) => void;
  removeDocument: (id: string) => void;
  updateDocument: (id: string, data: Partial<NewTransactionDocument>) => void;

  addSignee: (documentId: string, signee: NewTransactionSignee) => void;
  removeSignee: (documentId: string, signee: NewTransactionSignee) => void;
  updateSignee: (
    documentId: string,
    documentNumber: string,
    data: Partial<NewTransactionSignee>,
  ) => void;

  addAttachments: (
    documentId: string,
    data: NewTransactionAttachment[],
  ) => void;
  removeAttachment: (documentId: string, attachmentId: string) => void;
  updateAttachment: (
    documentId: string,
    attachmentId: string,
    data: Partial<NewTransactionAttachment>,
  ) => void;
}

const initialState = {
  notary: null,
  documents: [],
};

const useTransactionStore = create(
  devtools<ITransactionStore>((set) => ({
    ...initialState,

    reset: () => set(() => initialState),

    setId: (id) => set(() => ({ id })),
    setNotary: (notary) => set(() => ({ notary })),

    addDocument: async (document) => {
      set((state) => ({
        documents: [...state.documents, document],
      }));
    },

    removeDocument: (id) =>
      set((state) => ({
        documents: state.documents.filter((doc) => doc.id !== id),
      })),

    updateDocument: (id, data) =>
      set((state) => ({
        documents: state.documents.map((doc) =>
          doc.id === id ? { ...doc, ...data } : doc,
        ),
      })),

    addSignee: (documentId, data) =>
      set((state) => ({
        documents: state.documents.map((item) => {
          if (item.id === documentId) {
            return {
              ...item,
              signees: [...item.signees, data],
            };
          } else {
            return item;
          }
        }),
      })),

    removeSignee: (documentId, signee) =>
      set((state) => ({
        documents: state.documents.map((item) => {
          if (item.id === documentId) {
            return {
              ...item,
              signees: item.signees.filter((x) => {
                if (
                  x.documentNumber === signee.documentNumber &&
                  x.inRepresentationOf === signee.inRepresentationOf
                ) {
                  return false;
                } else {
                  return true;
                }
              }),
            };
          } else {
            return item;
          }
        }),
      })),

    updateSignee: (documentId, documentNumber, data) =>
      set((state) => ({
        documents: state.documents.map((item) => {
          if (item.id === documentId) {
            return {
              ...item,
              signees: item.signees.map((x) => {
                if (x.documentNumber === documentNumber) {
                  return {
                    ...x,
                    data,
                  };
                } else {
                  return x;
                }
              }),
            };
          } else {
            return item;
          }
        }),
      })),

    addAttachments: (documentId, data) =>
      set((state) => ({
        documents: state.documents.map((item) => {
          if (item.id === documentId) {
            return {
              ...item,
              attachments: [...item.attachments, ...data],
            };
          } else {
            return item;
          }
        }),
      })),

    removeAttachment: (documentId, attachmentId) =>
      set((state) => ({
        documents: state.documents.map((item) => {
          if (item.id === documentId) {
            return {
              ...item,
              attachments: item.attachments.filter(
                (x) => x.id !== attachmentId,
              ),
            };
          } else {
            return item;
          }
        }),
      })),

    updateAttachment: (documentId, attachmentId, data) =>
      set((state) => ({
        documents: state.documents.map((item) => {
          if (item.id === documentId) {
            return {
              ...item,
              attachments: item.attachments.map((x) => {
                if (x.id === attachmentId) {
                  return {
                    ...x,
                    ...data,
                  };
                } else {
                  return x;
                }
              }),
            };
          } else {
            return item;
          }
        }),
      })),
  })),
);

export { useTransactionStore };
