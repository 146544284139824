import { useAppStore } from "@/stores/AppStore";
import { useMemo } from "react";

const useNotifications = () => {
  const { notify } = useAppStore();
  return useMemo(
    () => ({
      warn: (message: string) => notify("WARN", message),
      info: (message: string) => notify("INFO", message),
      success: (message: string) => notify("SUCCESS", message),
      danger: (message: string) => notify("DANGER", message),
    }),
    [],
  );
};

export { useNotifications };
