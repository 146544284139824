import type {
  ITransactionStore,
  NewTransactionDocument,
} from "@/stores/TransactionStore";

import { Card, CardHeader } from "@/components/ui/card";
import { Separator } from "../ui/separator";
import { cn } from "@/lib/utils";
import { Badge } from "../ui/badge";

type FileItemProps = {
  document: NewTransactionDocument;
};

const FileItem = ({ document }: FileItemProps) => {
  return (
    <div
      className={cn(
        "group flex items-center justify-between overflow-hidden border-b p-6 transition-colors",
        "last:rounded-b last:border-0",
        "hover:bg-muted/40",
      )}
    >
      <div className="grow">
        <div className="text-lg font-bold">{document.title}</div>

        <div className="mt-2 flex flex-wrap items-center gap-1 text-sm md:gap-2">
          <div className="w-full grow md:w-auto md:grow-0">
            <Badge>{document.subcategory}</Badge>
          </div>

          <div>
            <Badge>
              {document.signees.length === 1
                ? "1 Firmante"
                : `${document.signees.length} Firmantes`}
            </Badge>
          </div>

          <div>
            <Badge>
              {document.attachments.length
                ? `${document.attachments.length} Anexos`
                : "Sin anexos"}
            </Badge>
          </div>
        </div>
      </div>

      <div className="flex-none"></div>
    </div>
  );
};

const TransactionReviewCard = ({ store }: { store: ITransactionStore }) => {
  return (
    <Card>
      <CardHeader>Documentos</CardHeader>

      <Separator />

      <div>
        {store.documents.map((item) => (
          <FileItem key={item.id} document={item} />
        ))}
      </div>
    </Card>
  );
};

export { TransactionReviewCard, FileItem };
