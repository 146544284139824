import { ReactNode } from "react";

const Heading = ({
  title,
  children,
  chip,
}: {
  title: string;
  children?: ReactNode;
  chip?: ReactNode;
}) => {
  return (
    <div className="flex flex-col items-center gap-3 md:flex-row md:justify-between">
      <div className="flex flex-row items-center gap-3">
        <h1 className="py-2 text-2xl font-bold">{title}</h1>
        {chip}
      </div>

      <div>{children}</div>
    </div>
  );
};

export { Heading };
