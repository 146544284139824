import { cn } from "@/lib/utils";
import { buttonVariants } from "@/components/ui/button";
import { NavLink, Outlet, useParams } from "react-router-dom";

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Skeleton } from "@/components/ui/skeleton";
import { useAppStore } from "@/stores/AppStore";
import { useQuery } from "@tanstack/react-query";
import { listLegalEntities } from "@/api/legal-entities";
import { TLegalEntityLayoutContext } from "@/pages/LegalEntities/context";
import { ReactNode, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { Separator } from "@/components/ui/separator";

const NavItem = (props: { to: string; label: string }) => {
  return (
    <NavLink
      end
      to={props.to}
      className={({ isActive }) =>
        cn(
          buttonVariants({ variant: "ghost" }),
          "justify-start font-semibold",
          isActive ? "bg-accent" : "hover:bg-transparent hover:underline",
        )
      }
    >
      {props.label}
    </NavLink>
  );
};

const LegalEntityLayout = () => {
  const { user } = useAppStore();
  const { legalEntityId } = useParams();

  const query = useQuery({
    queryKey: [
      "listLegalEntities",
      { userDocumentNumber: user?.documentNumber },
    ],
    queryFn: () =>
      listLegalEntities({ userDocumentNumber: user?.documentNumber as string }),
    enabled: !!user,
  });

  const currentLegalEntity = query.data?.find((x) => x.id === legalEntityId);

  return (
    <div>
      <div className="flex items-center justify-between">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink to="/">Inicio</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />

            <BreadcrumbItem>
              <BreadcrumbLink to="/legal-entities">Mis empresas</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />

            <BreadcrumbItem>
              {query.isLoading ? (
                <Skeleton className="h-5 w-28" />
              ) : (
                <BreadcrumbPage>{currentLegalEntity?.name}</BreadcrumbPage>
              )}
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>

        <div
          id="legal-entity-header-portal"
          className="flex h-10 items-center justify-end gap-4"
        ></div>
      </div>

      <div className="mt-4 grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-2">
          <nav className={cn("flex gap-2 md:flex-col")}>
            <NavItem to="" label="General" />
            <NavItem to="credits" label="Créditos" />
            <NavItem to="users" label="Usuarios" />
            <NavItem to="representatives" label="Rep. Legales" />
            <Separator />
            <NavItem to="labels" label="Etiquetas" />
          </nav>
        </div>

        <div className="col-span-12 md:col-span-10">
          <Outlet
            context={
              {
                legalEntity: currentLegalEntity,
                isLoading: query.isLoading,
              } satisfies TLegalEntityLayoutContext
            }
          />
        </div>
      </div>
    </div>
  );
};

const LegalEntityHeaderPortal = ({ children }: { children: ReactNode }) => {
  const ref = useRef<HTMLElement>();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    ref.current = document.querySelector(
      "#legal-entity-header-portal",
    ) as HTMLElement;
    setMounted(true);
  }, []);

  return mounted && ref.current ? createPortal(children, ref.current) : null;
};

export { LegalEntityLayout, LegalEntityHeaderPortal };
