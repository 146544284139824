import { AstronautIcon } from "@/components/Icons/AstronautIcon";
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";

const NotFoundPage = (): JSX.Element => {
  return (
    <div className="flex h-full grow flex-col items-center justify-center gap-10">
      <h1 className="text-4xl font-semibold">Página no encontrada</h1>

      <AstronautIcon height="300px" width="100%" />

      <div>
        <Button variant="default" size="lg" asChild>
          <Link to="/">
            <FontAwesomeIcon icon={faHouse} className="mr-3" />
            <span>Volver al inicio</span>
          </Link>
        </Button>
      </div>
    </div>
  );
};

export { NotFoundPage };
