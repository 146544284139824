import { z } from "zod";
import { firmeClient } from "@/api/utils";

import { ProcedureTypeEnum } from "@/enums/ProcedureType.enum.ts";
import { SigneeTypeRepresentation } from "@/enums/SigneeTypeRepresentation.enum.ts";

// New API endpoints
export const DocumentStatusMap = {
  DRAFT: "DRAFT",
  PENDING_SIGNATURE: "PENDING_SIGNATURE",
  PENDING_NOTARY_SIGNATURE: "PENDING_NOTARY_SIGNATURE",
  NOTARIZED: "NOTARIZED",
  MISSING_ATTACHMENT: "MISSING_ATTACHMENT",
  REPAIR: "REPAIR",
  REJECTED: "REJECTED",
  CANCELLED: "CANCELLED",
  COMPLETED: "COMPLETED",
  REFUNDED: "REFUNDED",
} as const;

const DocumentStatusSchema = z.nativeEnum(DocumentStatusMap);
type DocumentStatusKeys = keyof typeof DocumentStatusMap;
export type TDocumentStatus = (typeof DocumentStatusMap)[DocumentStatusKeys];

type TPaginationFilters = {
  page?: number;
  pageSize?: number;
};

type TPagedResponse<TItem> = {
  page: number;
  page_size: number;
  total: number;
  items: TItem[];
};

type ListDocumentsFilters = TPaginationFilters & {
  legalEntityId: string;
};

const SigneeItemRepresentation = z.object({
  type: z.nativeEnum(SigneeTypeRepresentation),
  documentNumber: z.string(),
  name: z.string(),
});

const DocumentSigneeItem = z.object({
  documentNumber: z.string(),
  country: z.string(),
  phoneNumber: z.string().nullish(),
  firstName: z.string(),
  lastName: z.string(),
  maternalLastName: z.string().nullish(),
  email: z.string(),
  hasIdentityCard: z.boolean(),
  municipality: z.string().nullish(),
  privateCode: z.string(),
  approvedUntil: z.string(),
  signedOn: z.string().nullish(),
  notarizedOn: z.string().nullish(),
  isVerified: z.boolean().nullish(),
  notary: z.object({
    id: z.string(),
    name: z.string(),
  }),
  coordinates: z
    .object({
      longitude: z.number().nullish(),
      latitude: z.number().nullish(),
    })
    .nullish(),
  coordinatesPdf: z
    .object({
      code: z.string(),
      page: z.number(),
      x: z.number(),
      y: z.number(),
    })
    .array()
    .nullish(),
  inRepresentationOf: SigneeItemRepresentation.nullish(),
});

const SupportingDocumentSchema = z.object({
  name: z.string(),
  code: z.string(),
  description: z.string().nullish(),
  contentType: z.string(),
});

const DocumentItemSchema = z.object({
  id: z.string(),
  code: z.string(),
  name: z.string(),
  category: z.string(),
  subcategory: z.string(),
  status: DocumentStatusSchema,
  isProtocolized: z.boolean(),
  isArchived: z.boolean(),
  isNotarized: z.boolean(),
  signedOn: z.coerce.date().nullable(),
  notarizedOn: z.coerce.date().nullable(),
  transactionId: z.string(),
  procedureType: z.nativeEnum(ProcedureTypeEnum),
  user: z.object({
    id: z.string(),
    firstName: z.string(),
    lastName: z.string(),
  }),
  legalEntity: z.object({
    id: z.string(),
    name: z.string(),
    documentNumber: z.string(),
  }),
  signees: z.array(DocumentSigneeItem),
  supportingDocuments: z.array(SupportingDocumentSchema),
  labels: z.array(z.string()),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

const DocumentsListItemSchema = z.object({
  id: z.string(),
  code: z.string(),
  name: z.string(),
  category: z.string(),
  subcategory: z.string(),
  status: DocumentStatusSchema,
  isProtocolized: z.boolean(),
  isArchived: z.boolean(),
  isNotarized: z.boolean(),
  signedOn: z.coerce.date().nullable(),
  notarizedOn: z.coerce.date().nullable(),
  transactionId: z.string(),
  procedureType: z.string(),
  user: z.object({
    id: z.string(),
    firstName: z.string(),
    lastName: z.string(),
  }),
  legalEntity: z.object({
    id: z.string(),
    name: z.string(),
    documentNumber: z.string(),
  }),
  signeesCount: z.object({
    signed: z.number(),
    total: z.number(),
  }),
  labels: z.string().array(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

const DocumentLogItemSchema = z.object({
  id: z.string(),
  action: z.string(),
  description: z.string().nullable(),
  user: z
    .object({
      name: z.string(),
      email: z.string(),
    })
    .nullable(),
  createdAt: z.coerce.date(),
});

const UpdateDocumentSchema = z.object({
  code: z.string(),
  labels: z.string().array().optional(),
});

export type TDocumenSignee = z.infer<typeof DocumentSigneeItem>;
export type TSupportingDocumentItem = z.infer<typeof SupportingDocumentSchema>;
export type TDocumentItem = z.infer<typeof DocumentItemSchema>;
export type TListDocumentItem = z.infer<typeof DocumentsListItemSchema>;
export type TDocumentLogItem = z.infer<typeof DocumentLogItemSchema>;
export type TUpdateDocumentData = z.infer<typeof UpdateDocumentSchema>;

export const listDocuments = async (filters: ListDocumentsFilters) => {
  return firmeClient
    .get<TPagedResponse<TListDocumentItem>>("/documents", {
      params: filters,
    })
    .then((res) => res.data);
};

export const getDocumentByCode = async (code: string) => {
  return firmeClient
    .get<TDocumentItem>(`/documents/${code}`)
    .then((res) => DocumentItemSchema.parse(res.data));
};

export const updateDocument = async ({
  code,
  ...data
}: TUpdateDocumentData) => {
  return firmeClient
    .put<TDocumentItem>(`/documents/${code}`, data)
    .then((res) => DocumentItemSchema.parse(res.data));
};

export const authorizeDocument = async ({ code }: TUpdateDocumentData) => {
  return firmeClient
    .post<TDocumentItem>(`/documents/${code}/authorize`)
    .then((res) => DocumentItemSchema.parse(res.data));
};

export const listDocumentLogs = async ({
  documentCode,
}: {
  documentCode: string;
}) => {
  return firmeClient
    .get<TPagedResponse<TDocumentLogItem>>(`/documents/${documentCode}/logs`)
    .then((res) => res.data);
};
