import { esRutValido, formatearRut } from "@/helpers/rut.helpers";
import { cn } from "@/lib/utils";
import { ForwardedRef, forwardRef, useState } from "react";
import { FormInput } from "./Form";

export const RUTInput = forwardRef(
  (
    {
      name = "documentNumber",
      rut,
      setRut,
      disabled,
      fullSize = false,
      onFocus = () => {},
      hasError = false,
      register = () => [],
    }: {
      name?: string;
      rut: string;
      setRut: (rut: string) => void;
      validRut?: boolean;
      setValidRut?: (validRut: boolean) => void;
      fullSize?: boolean;
      onFocus?: () => void;
      hasError?: boolean;
      register?: any; // eslint-disable-line
      disabled?: boolean;
    },
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const [validRut, setValidRut] = useState(true);

    const handleRutChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const currentRut = e.target.value.replace(/[^0-9kK]+/g, "").toUpperCase();

      if (
        currentRut.length >= 8 &&
        currentRut.length <= 9 &&
        !esRutValido(currentRut)
      ) {
        setValidRut(false);
      } else {
        setValidRut(true);
      }

      const formattedRut = formatearRut(currentRut);
      if (formattedRut && currentRut.length >= 2 && currentRut.length <= 9) {
        setRut(formattedRut);
      } else if (currentRut.length <= 9) {
        setRut(currentRut);
      }
    };

    return (
      <div
        className={`flex flex-col items-center justify-center gap-2 ${
          fullSize && "w-full"
        }`}
      >
        <FormInput
          id={name}
          name={name}
          value={rut}
          ref={ref}
          onChange={handleRutChange}
          placeholder="12.345.678-9"
          type="text"
          className={cn(fullSize && "w-full")}
          onFocus={onFocus}
          disabled={disabled}
          {...register(name)}
        />
        {(!validRut || hasError) && (
          <p className="w-full text-left text-sm text-destructive">
            El rut ingresado no es válido
          </p>
        )}
      </div>
    );
  },
);
