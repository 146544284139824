import { useAppStore } from "@/stores/AppStore";
import { apiClient } from "./utils.ts";
import { updateDocument } from "./documents.ts";

export type TransactionPaymentStatus =
  | "PENDING"
  | "PAID"
  | "REJECTED"
  | "IN_REVIEW"
  | "UNINVOICED"
  | "INVOICED";

export type GetTransactionsResponseItem = {
  id: string;
  documentsCodes: string[];
  legalEntityDocumentNumber: string;
  status: string;
  paymentStatus: TransactionPaymentStatus;
  mercadoPagoItems: [];
  mercadoPagoId?: string;
  mercadoPagoUrl?: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateTransactionArgs = {
  legalEntityDocumentNumber: string;
  documentsCodes: string[];
  notary: {
    id: string;
    name: string;
  };
  signees: {
    documentCode: string;
    documentNumber: string;
    firstName: string;
    lastName: string;
    maternalLastName: string;
    email: string;
    countryCode: string;
    phoneNumber?: string;
  }[];
};

export const getTransactions = async () => {
  const { legalEntity } = useAppStore.getState();

  if (!legalEntity) {
    throw new Error("No legal entity set");
  }

  return apiClient
    .get<
      GetTransactionsResponseItem[]
    >(`/transactions/legal-entities/${legalEntity?.documentNumber}`)
    .then((res) => res.data);
};

export type CreateTransactionResponse = {
  id: string;
  documentsCodes: string[];
  legalEntityDocumentNumber: string;
  status: string;
  paymentStatus: string;
  mercadoPagoItems: {}[]; // eslint-disable-line
  createdAt: string;
  updatedAt: string;
};

export const getTransactionById = async (transactionId: string) => {
  return apiClient
    .get<GetTransactionsResponseItem>(`/transactions/${transactionId}`)
    .then((res) => res.data);
};

export const createTransaction = async (data: CreateTransactionArgs) => {
  return apiClient
    .post<CreateTransactionResponse>(`/transactions`, data)
    .then((res) => res.data);
};

export const inviteAllToSign = async ({
  transactionId,
  documentCodes,
}: {
  transactionId: string;
  documentCodes: string[];
}) => {
  // Update transaction's status to PAID and PENDING
  await apiClient.put(`/transactions/${transactionId}`, {
    status: "PENDING",
    paymentStatus: "PAID",
  });

  // Update documents status to PENDING_SIGNATURE
  for await (const code of documentCodes) {
    await updateDocument({
      documentCode: code,
      status: "PENDING_SIGNATURE",
    });
  }

  return apiClient
    .put<CreateTransactionResponse>(
      `/transactions/${transactionId}/invite-all`,
      null,
    )
    .then((res) => res.data);
};

export const createTransactionPayment = async ({
  transactionId,
}: {
  transactionId: string;
}) => {
  return apiClient
    .post<{
      id: string;
      url: string;
    }>(`/preferences`, {
      transactionId,
    })
    .then((res) => res.data);
};
