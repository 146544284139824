import { ProcedureTypeEnum } from "@/enums/ProcedureType.enum";
import { firmeClient } from "../utils";

export type NotaryItem = {
  id: string;
  name: string;
  procedures: ProcedureTypeEnum[];
};

export const getNotaries = async () => {
  return firmeClient.get<NotaryItem[]>("/notaries").then((res) => res.data);
};
