import { assign, createMachine } from "xstate";

import { useTransactionStore } from "./TransactionStore";
import { createDocuments, uploadDocumentFile } from "@/api/documents";
import { CreateTransactionArgs, createTransaction } from "@/api/transactions";
import { ProcedureTypeEnum } from "@/enums/ProcedureType.enum";
import { AxiosError } from "axios";

interface IMachineContext {
  subId?: string;
  legalEntityDocumentNumber?: string;
  error?: string;
}

const transactionMachine = createMachine<IMachineContext>({
  id: "transaction-machine",
  initial: "IDLE",
  predictableActionArguments: true,
  states: {
    IDLE: {
      on: {
        CREATE: "CREATE_DOCUMENTS_IN_PROGRESS",
      },
    },

    CREATE_DOCUMENTS_IN_PROGRESS: {
      invoke: {
        src: async ({ subId, legalEntityDocumentNumber }) => {
          const store = useTransactionStore.getState();

          if (!subId || !legalEntityDocumentNumber) {
            throw new Error("Missing subId or legalEntityDocumentNumber");
          }

          const docs = await createDocuments({
            subId,
            documents: store.documents.map((doc) => ({
              legalEntityDocumentNumber,
              category: doc.category as string,
              subcategory: doc.subcategory as string,
              name: doc.title,
              ownerSigns: false,
              isNotarized: false,
              needsVideo: true,
              contentType: "application/pdf",
              supportingDocuments: doc.attachments.map((attachment) => ({
                code: attachment.id,
                name: attachment.name,
                subcategory: attachment.subcategory,
                contentType: attachment.contentType,
              })),
              isProtocolized: doc.isProtocolized as boolean,
              procedureType: doc.procedureType as ProcedureTypeEnum,
            })),
          });

          // Add documentCode and documentId from documents creation server
          // response, assuming that the response returns the items in the same
          // order as sent
          let idx = 0;
          for (const doc of docs) {
            const current = store.documents.at(idx);

            if (current) {
              store.updateDocument(current.id, {
                documentId: doc.id,
                documentCode: doc.code,
              });
            }
            idx++;
          }
        },
        onDone: {
          target: "UPLOAD_DOCUMENTS_IN_PROGRESS",
        },
        onError: {
          target: "CREATE_DOCUMENTS_FAILED",
          actions: assign({
            error: (_, e) => {
              const error = e.data as AxiosError;
              const data = error.response?.data as {
                error: {
                  message: string;
                };
              };

              if (
                data.error.message.includes("quota") &&
                data.error.message.includes("is exceeded")
              ) {
                return "No puede continuar porque se ha excedido el máximo de créditos disponibles";
              }
            },
          }),
        },
      },
    },
    CREATE_DOCUMENTS_FAILED: {
      on: {
        RETRY: "CREATE_DOCUMENTS_IN_PROGRESS",
      },
    },

    UPLOAD_DOCUMENTS_IN_PROGRESS: {
      invoke: {
        src: async () => {
          const store = useTransactionStore.getState();

          for await (const document of store.documents) {
            if (!document.documentId) {
              throw new Error("Missing documentId");
            }

            await uploadDocumentFile({
              documentId: document.documentId,
              fileType: "DOCUMENT",
              fileKey: "INITIAL_DOCUMENT.pdf",
              contents: document.contents,
            });
          }
        },
        onDone: {
          target: "UPLOAD_ATTACHMENTS_IN_PROGRESS",
        },
        onError: {
          target: "UPLOAD_DOCUMENTS_FAILED",
        },
      },
    },
    UPLOAD_DOCUMENTS_FAILED: {
      on: {
        RETRY: "UPLOAD_ATTACHMENTS_IN_PROGRESS",
      },
    },

    UPLOAD_ATTACHMENTS_IN_PROGRESS: {
      invoke: {
        src: async () => {
          const store = useTransactionStore.getState();

          for (const document of store.documents) {
            if (!document.documentId) {
              throw new Error("Missing documentId");
            }

            for await (const attachment of document.attachments) {
              await uploadDocumentFile({
                documentId: document.documentId,
                fileType: "ATTACHMENT",
                fileKey: attachment.name,
                contents: attachment.contents,
              });
            }
          }
        },
        onDone: {
          target: "CREATE_TRANSACTION_IN_PROGRESS",
        },
        onError: {
          target: "UPLOAD_ATTACHMENTS_IN_PROGRESS",
        },
      },
    },
    UPLOAD_ATTACHMENTS_FAILED: {
      on: {
        RETRY: "UPLOAD_ATTACHMENTS_IN_PROGRESS",
      },
    },

    CREATE_TRANSACTION_IN_PROGRESS: {
      invoke: {
        src: async ({ legalEntityDocumentNumber }) => {
          const store = useTransactionStore.getState();

          if (!legalEntityDocumentNumber || !store.notary) {
            throw new Error("Missing legalEntityDocumentNumber or notary");
          }

          const documentsCodes: string[] = [];
          const signees: CreateTransactionArgs["signees"] = [];

          for (const document of store.documents) {
            if (document.documentCode) {
              documentsCodes.push(document.documentCode);
              signees.push(
                ...document.signees.map((item) => ({
                  documentCode: document.documentCode as string,
                  documentNumber: item.documentNumber,
                  firstName: item.firstName,
                  lastName: item.lastName,
                  maternalLastName: item.maternalLastName ?? "",
                  email: item.email,
                  countryCode: "CL",
                  inRepresentationOf: item.inRepresentationOf,
                  phoneNumber: item.phoneNumber,
                })),
              );
            }
          }

          const trx = await createTransaction({
            legalEntityDocumentNumber,
            documentsCodes,
            notary: {
              id: store.notary.id,
              name: store.notary.name,
            },
            signees,
          });

          store.setId(trx.id);
        },
        onDone: {
          target: "CREATE_TRANSACTION_COMPLETE",
        },
        onError: {
          target: "CREATE_TRANSACTION_FAILED",
        },
      },
    },
    CREATE_TRANSACTION_FAILED: {
      on: {
        RETRY: "CREATE_TRANSACTION_IN_PROGRESS",
      },
    },

    CREATE_TRANSACTION_COMPLETE: {
      type: "final",
    },
  },
});

export { transactionMachine };
