export function esRutValido(rutSinFormato: string): boolean {
  if (!rutSinFormato || rutSinFormato.length < 2) {
    return false;
  }

  const rut = rutSinFormato.replace(/[^0-9kK]+/g, "").toUpperCase();

  let cuerpo = parseInt(rut.slice(0, -1), 10);
  let dv = rut.slice(-1);
  let m = 0,
    s = 1;

  for (; cuerpo; cuerpo = Math.floor(cuerpo / 10)) {
    s = (s + (cuerpo % 10) * (9 - (m++ % 6))) % 11;
  }

  if (dv === "10") {
    dv = "K";
  }

  return dv === String(s ? s - 1 : "K");
}

export function formatearRut(rutSinFormato: string): string {
  // if (!esRutValido(rutSinFormato)) return;

  const rutLimpio = rutSinFormato.replace(/[^0-9kK]+/g, "").toUpperCase();
  const cuerpo = rutLimpio.slice(0, -1);
  const dv = rutLimpio.slice(-1);
  let rutFormateado = "";

  let j = 0;
  for (let i = cuerpo.length - 1; i >= 0; i--) {
    rutFormateado = cuerpo.charAt(i) + rutFormateado;
    j++;
    if (j % 3 === 0 && i > 0) {
      rutFormateado = "." + rutFormateado;
    }
  }

  rutFormateado = rutFormateado + "-" + dv;

  return rutFormateado;
}
