import { type NewTransactionSignee } from "@/stores/TransactionStore";
import { Dispatch, ElementRef, SetStateAction, useRef, useState } from "react";

import { Button } from "@/components/ui/button";
import { Field, Fieldset } from "@/components/Fieldset";
import { Form, FormInput } from "@/components/Form";
import { SigneeTypeRepresentation } from "@/enums/SigneeTypeRepresentation.enum";
// import { Checkbox, InputLabel } from "@mui/material";
import { ZodFormattedError, z } from "zod";
import { RUTInput } from "@/components/RUTInput";
import { esRutValido, formatearRut } from "@/helpers/rut.helpers";
import { toast } from "sonner";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
} from "@/components/ui/select";
import { Checkbox } from "../ui/checkbox";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "@/components/ui/command";

import { useAppStore } from "@/stores/AppStore";
import { listLegalEntityRepresentatives } from "@/api/legal-entities";
import { useQuery } from "@tanstack/react-query";

type NewSigneeFormProps = {
  onSubmit: (data: NewTransactionSignee, reset: () => void) => void;
  setSelectedInput: Dispatch<
    SetStateAction<
      "" | "documentNumber" | "firstName" | "lastName" | "maternalLastName"
    >
  >;
};

const RepresentativesPicker = ({
  onSubmit,
}: {
  onSubmit: (data: NewTransactionSignee, reset: () => void) => void;
}) => {
  const { legalEntity } = useAppStore();

  const query = useQuery({
    queryKey: ["listRepresentatives", { legalEntityId: legalEntity?.id }],
    queryFn: () =>
      listLegalEntityRepresentatives({
        legalEntityId: legalEntity?.id as string,
      }),
    enabled: !!legalEntity,
  });

  const representatives = query.data ?? [];

  return (
    <div className="rounded-md border">
      <Command>
        <CommandInput placeholder="Buscar por nombre o RUT" />
        <CommandList>
          <CommandEmpty>No se encontraron Representantes Legales</CommandEmpty>
          <CommandGroup>
            {representatives.map((item) => (
              <CommandItem
                key={`${item.firstName}-${item.lastName}-${item.maternalLastName}-${item.email}-${item.documentNumber}`}
                className="flex flex-row items-center justify-between"
              >
                <div className="flex flex-col items-start justify-center">
                  <div>
                    {item.firstName} {item.lastName} {item.maternalLastName}
                  </div>
                  <div>{formatearRut(item.documentNumber)}</div>
                </div>

                <div>
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => {
                      if (legalEntity) {
                        onSubmit(
                          {
                            documentNumber: item.documentNumber,
                            firstName: item.firstName,
                            lastName: item.lastName,
                            maternalLastName: item.maternalLastName,
                            email: item.email,
                            country: "CHILE",
                            inRepresentationOf: {
                              type: SigneeTypeRepresentation.Legal,
                              documentNumber: legalEntity?.documentNumber,
                              name: legalEntity?.name,
                            },
                          },
                          () => {},
                        );
                      }
                    }}
                  >
                    Agregar
                  </Button>
                </div>
              </CommandItem>
            ))}
          </CommandGroup>
          <CommandSeparator />
        </CommandList>
      </Command>
    </div>
  );
};

const formDataSchema = z.object({
  documentNumber: z
    .string()
    .min(1, "RUT es requerido")
    .refine((value) => {
      return esRutValido(value);
    }),
  firstName: z.string().min(1, "Nombre es requerido"),
  lastName: z.string().min(1, "Apellido paterno es requerido"),
  maternalLastName: z.string().min(1, "Apellido materno es requerido"),
  phoneNumber: z.string(),
  email: z.string().email("Correo electrónico ingresado no es válido"),
  country: z.string().default("CHILE"),
  repType: z.string().optional(),
  repDocumentNumber: z
    .string()
    .refine((value) => {
      return esRutValido(value ?? "");
    })
    .optional(),
  repFirstName: z
    .string()
    .min(1, "Ingrese los nombres de la persona a representar")
    .optional(),
  repLastName: z
    .string()
    .min(1, "Ingrese los apellidos de la persona a representar")
    .optional(),
});

const AddSigneeForm = ({ onSubmit, setSelectedInput }: NewSigneeFormProps) => {
  const [repType, setRepType] = useState(SigneeTypeRepresentation.Legal);

  const [documentNumber, setDocumentNumber] = useState("");
  const [repRut, setRepRut] = useState("");

  const [errors, setErrors] =
    useState<ZodFormattedError<z.infer<typeof formDataSchema>>>();

  const [repErrors, setRepErrors] = useState<string>();

  const firstInputRef = useRef<ElementRef<"input">>(null);

  const [isRepresentative, setIsRepresentative] = useState(false);

  return (
    <Tabs defaultValue="account" className="w-full">
      <TabsList className="mb-3 w-full">
        <TabsTrigger value="account" className="w-full">
          Nuevo firmante
        </TabsTrigger>
        <TabsTrigger value="password" className="w-full">
          Representantes legales
        </TabsTrigger>
      </TabsList>

      <TabsContent value="account">
        <Form
          onSubmit={(e) => {
            e.preventDefault();

            const data = new FormData(e.currentTarget);
            // eslint-disable-next-line
            const parsed: any = formDataSchema.safeParse(
              Object.fromEntries(data),
            );

            setErrors(undefined);

            if (!parsed.success) {
              setErrors(parsed.error.format());
            } else if (
              parsed.data.documentNumber === parsed.data.repDocumentNumber
            ) {
              toast.error("Un firmante no se puede representar a si mismo.");
            } else {
              if (isRepresentative) {
                if (
                  !parsed.data.repType ||
                  !parsed.data.repFirstName ||
                  (parsed.data.repType === SigneeTypeRepresentation.Natural &&
                    !parsed.data.repLastName) ||
                  !repRut
                ) {
                  setRepErrors("Todos los campos son requeridos");
                } else {
                  setErrors(undefined);
                  setRepErrors(undefined);

                  parsed.data.inRepresentationOf = {};
                  parsed.data.inRepresentationOf.type = parsed.data.repType;
                  parsed.data.inRepresentationOf.documentNumber = repRut;

                  if (
                    parsed.data.repType === SigneeTypeRepresentation.Natural
                  ) {
                    parsed.data.inRepresentationOf.name =
                      parsed.data.repFirstName + " " + parsed.data.repLastName;
                  } else {
                    parsed.data.inRepresentationOf.name =
                      parsed.data.repFirstName;
                  }

                  delete parsed.data.repType;
                  delete parsed.data.repDocumentNumber;
                  delete parsed.data.repFirstName;
                  delete parsed.data.repLastName;

                  onSubmit(parsed.data, () => {
                    e.currentTarget.reset();
                    setRepRut("");
                    setIsRepresentative(false);
                    setDocumentNumber("");
                  });
                  firstInputRef.current?.focus();
                }
              } else {
                setErrors(undefined);
                setRepErrors(undefined);
                onSubmit(parsed.data, () => {
                  e.currentTarget.reset();
                  setRepRut("");
                  setIsRepresentative(false);
                  setDocumentNumber("");
                });
                firstInputRef.current?.focus();
              }
            }
          }}
        >
          <Fieldset animate>
            <Field label="RUT">
              <RUTInput
                ref={firstInputRef}
                fullSize
                rut={documentNumber}
                setRut={setDocumentNumber}
                onFocus={() => setSelectedInput("documentNumber")}
                hasError={!!errors?.documentNumber}
              />
            </Field>

            <Field label="País">
              <Select>
                <SelectTrigger>CHILE</SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem value="CHILE">CHILE</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </Field>

            <Field label="Nombres" error={errors?.firstName}>
              <FormInput
                className="w-full"
                name="firstName"
                hasError={!!errors?.firstName}
                onFocus={() => setSelectedInput("firstName")}
              />
            </Field>

            <Field label="Apellido Paterno" error={errors?.lastName}>
              <FormInput
                className="w-full"
                name="lastName"
                hasError={!!errors?.lastName}
                onFocus={() => setSelectedInput("lastName")}
              />
            </Field>

            <Field label="Apellido Materno" error={errors?.maternalLastName}>
              <FormInput
                className="w-full"
                name="maternalLastName"
                hasError={!!errors?.maternalLastName}
                onFocus={() => setSelectedInput("maternalLastName")}
              />
            </Field>

            <Field label="Email" error={errors?.email}>
              <FormInput
                type="email"
                className="w-full"
                name="email"
                hasError={!!errors?.email}
                placeholder="Utilizar correo electrónico personal y no corporativo"
                onFocus={() => setSelectedInput("")}
              />
            </Field>

            <Field
              label="Número de teléfono (opcional)"
              error={errors?.phoneNumber}
            >
              <FormInput
                type="text"
                className="w-full"
                name="phoneNumber"
                hasError={!!errors?.phoneNumber}
                placeholder="+56 9 1234 5678"
                onFocus={() => setSelectedInput("")}
              />
            </Field>

            <Field label="En representación">
              <div className="flex h-10 flex-row items-center justify-start">
                <label
                  htmlFor="representative"
                  className="flex cursor-pointer flex-row items-center gap-3"
                >
                  <Checkbox
                    id="representative"
                    checked={isRepresentative}
                    onCheckedChange={(e) => {
                      setIsRepresentative(e !== "indeterminate" ? e : false);
                    }}
                  />
                  ¿Firmar en representación de otra persona?
                </label>
              </div>
            </Field>

            {isRepresentative && (
              <>
                <Field
                  label="Tipo de persona a representar"
                  error={errors?.repType}
                >
                  <Select
                    onValueChange={(v) =>
                      setRepType(v as SigneeTypeRepresentation)
                    }
                    value={repType}
                    key={`repType-${isRepresentative}`}
                    name="repType"
                  >
                    <SelectTrigger>
                      {repType === SigneeTypeRepresentation.Natural
                        ? "Persona Natural"
                        : repType === SigneeTypeRepresentation.Legal
                          ? "Persona Jurídica"
                          : "Seleccionar tipo de persona a representar"}
                    </SelectTrigger>

                    <SelectContent>
                      <SelectGroup>
                        <SelectItem value={SigneeTypeRepresentation.Legal}>
                          Persona Jurídica
                        </SelectItem>
                        <SelectItem value={SigneeTypeRepresentation.Natural}>
                          Persona Natural
                        </SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </Field>

                <Field
                  label={
                    repType === SigneeTypeRepresentation.Natural
                      ? "Nombres de la persona a representar"
                      : "Razón social de la persona a representar"
                  }
                  error={errors?.repFirstName}
                >
                  <FormInput
                    key={`repFirstName-${isRepresentative}`}
                    className="w-full"
                    name="repFirstName"
                    id="repFirstName"
                    hasError={!!errors?.repFirstName}
                    onFocus={() => setSelectedInput("firstName")}
                  />
                </Field>

                {!!repType && repType === SigneeTypeRepresentation.Natural && (
                  <Field
                    label="Apellidos de la persona a representar"
                    error={errors?.repLastName}
                  >
                    <FormInput
                      key={`repLastName-${isRepresentative}`}
                      className="w-full"
                      name="repLastName"
                      id="repLastName"
                      hasError={!!errors?.repLastName}
                      onFocus={() => setSelectedInput("lastName")}
                    />
                  </Field>
                )}

                <Field label="RUT de la persona a representar">
                  <RUTInput
                    name="repDocumentNumber"
                    key={`repRut-${isRepresentative}`}
                    rut={repRut}
                    setRut={setRepRut}
                    hasError={!!errors?.repDocumentNumber}
                    fullSize
                    onFocus={() => setSelectedInput("documentNumber")}
                  />
                </Field>

                {isRepresentative && repErrors && (
                  <Field>
                    <div className="flex items-center justify-end gap-3">
                      <span className="text-xs font-bold text-destructive">
                        {repErrors}
                      </span>
                    </div>
                  </Field>
                )}
              </>
            )}
          </Fieldset>

          <div className="mt-6 flex items-center gap-3">
            <Button className="w-full lg:w-auto" type="submit">
              Agregar Firmante
            </Button>
          </div>
        </Form>
      </TabsContent>

      <TabsContent value="password">
        <RepresentativesPicker onSubmit={onSubmit} />
      </TabsContent>
    </Tabs>
  );
};

export { AddSigneeForm };
