import { v4 as uuidv4 } from "uuid";
import { FileRejection, useDropzone } from "react-dropzone";
import { useCallback } from "react";
import { useAutoAnimate } from "@formkit/auto-animate/react";

import type {
  NewTransactionAttachment,
  NewTransactionDocument,
} from "@/stores/TransactionStore";

import {
  faClose,
  faCloudUpload,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";

import { Card, CardHeader } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "sonner";
import { Separator } from "../ui/separator";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/tooltip";

type AttachmentsListProps = {
  attachments: NewTransactionAttachment[];
  onRemove: (attachmentId: string) => void;
};

const AttachmentsList = ({ attachments, onRemove }: AttachmentsListProps) => {
  const [parent] = useAutoAnimate();

  return (
    <div ref={parent}>
      {attachments.map((item) => (
        <div
          key={item.id}
          className="flex items-center justify-between border-b py-2 last:rounded-b last:border-0 hover:bg-muted/40"
        >
          <div className="flex items-center justify-start truncate p-4">
            <FontAwesomeIcon
              icon={faFilePdf}
              size="lg"
              className="text-primary-darker w-6 flex-none pr-4"
            />
            <div>{item.name}</div>
          </div>

          <div className="flex-none px-4">
            <Button variant="destructive" onClick={() => onRemove(item.id)}>
              <FontAwesomeIcon icon={faClose} className="mr-2" />
              <span className="hidden md:inline">Eliminar</span>
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
};

type DocumentAttachmentsCardProps = {
  document: NewTransactionDocument;
  attachments: NewTransactionAttachment[];
  onAdd: (ids: NewTransactionAttachment[]) => void;
  onRemove: (id: string) => void;
};

const DocumentAttachmentsCard = ({
  document,
  attachments,
  onAdd,
  onRemove,
}: DocumentAttachmentsCardProps) => {
  const onDrop = useCallback(
    async (files: File[]) => {
      const attachments: NewTransactionAttachment[] = [];

      for await (const file of files) {
        attachments.push({
          id: uuidv4(),
          name: file.name,
          contentType: file.type,
          subcategory: document.subcategory as string,
          contents: file,
          size: file.size,
          code: document.title,
        });
      }

      onAdd(attachments);
    },
    [onAdd],
  );

  const onDropRejected = useCallback(
    (fileRejections: FileRejection[]): void => {
      fileRejections.forEach((fileRejection) => {
        toast.error(
          `El archivo ${fileRejection.file.name} no pudo ser agregado. ${
            fileRejection.errors[0].message.startsWith("File is larger than")
              ? "El archivo es demasiado grande."
              : "El archivo no es un PDF."
          }`,
        );
      });
    },
    [],
  );

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    onDropRejected,
    accept: {
      "application/pdf": [".pdf"],
    },
    maxSize: 10 * 1024 * 1024,
  });

  return (
    <Card>
      <CardHeader className="flex flex-row items-center justify-between">
        <div>Anexos</div>

        {attachments.length > 0 && (
          <div>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button className="mx-auto" onClick={open} size="sm">
                    <FontAwesomeIcon icon={faCloudUpload} className="mr-2" />
                    <span>Agregar más archivos</span>
                  </Button>
                </TooltipTrigger>

                <TooltipContent side="left">
                  <p>Tamaño Máximo por archivo: 10 MB</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        )}
      </CardHeader>

      <Separator className="mb-0" />

      <AttachmentsList attachments={attachments} onRemove={onRemove} />

      <input {...getInputProps()} type="file" className="hidden" />

      {!attachments.length ? (
        <div
          {...getRootProps()}
          className="m-6 cursor-pointer rounded border-2 border-dashed border-slate-400 bg-slate-100 p-4 text-center transition-colors hover:bg-slate-200 dark:border-slate-700 dark:bg-slate-800 dark:hover:bg-slate-900"
        >
          <div className="md:hidden">
            Haz click para seleccionar archivos (Solo archivos en formato PDF)
          </div>
          <div className="hidden md:block">
            {isDragActive ? (
              "Suelta aquí el archivo"
            ) : (
              <span>
                Arrastra tus archivos aquí o haz click para seleccionarlos
                <br /> Solo archivos en formato PDF.
                <br /> Tamaño máximo por archivo: 10MB
              </span>
            )}
          </div>
        </div>
      ) : null}
    </Card>
  );
};

export { DocumentAttachmentsCard };
