import { useQuery } from "@tanstack/react-query";
import { Outlet } from "react-router-dom";

import { Header } from "@/components/Header/Header";
import { useAppStore } from "@/stores/AppStore";
import { getCurrentUser } from "@/api/users";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Sidebar } from "@/components/Sidebar";
import { PersonTypeEnum } from "@/api/legal-entities";

const useFetchCurrentUser = ({
  isAuthenticated,
}: {
  isAuthenticated: boolean;
}) => {
  const { setUser, setLegalEntity, legalEntity } = useAppStore();

  return useQuery(["getCurrentUser"], getCurrentUser, {
    refetchOnWindowFocus: false,
    enabled: isAuthenticated,
    onSuccess: (data) => {
      setUser(data);

      if (!legalEntity && data.legalEntities.length > 0) {
        const firstLegalEntity = data.legalEntities[0];
        setLegalEntity({
          ...firstLegalEntity,
          legalRepresentatives: [],
          personType: PersonTypeEnum.JURIDICAL,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        });
      }
    },
  });
};

const RootLayout = () => {
  const { authStatus } = useAuthenticator((context) => [context.user]);
  useFetchCurrentUser({ isAuthenticated: authStatus === "authenticated" });

  return (
    <div className="flex h-screen flex-col items-stretch">
      <Header />
      {authStatus === "authenticated" && <Sidebar />}
      <Outlet />
      <footer className="pointer-events-none py-8"></footer>
    </div>
  );
};

export { RootLayout };
