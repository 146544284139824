import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import logotipo from "@/assets/images/firme.png";

import {
  Authenticator,
  AuthenticatorProps,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

// const AppWithAuth = withAuthenticator(App, {
//   variation: "modal",
//   formFields: {
//     signIn: {
//       username: {
//         label: "Correo electrónico",
//         placeholder: "Correo electrónico",
//       },
//       password: {
//         label: "Contraseña",
//         placeholder: "Contraseña",
//       },
//     },
//     signUp: {
//       "custom:documentNumber": {
//         label: "RUT",
//         placeholder: "RUT",
//         order: 1,
//       },
//       email: {
//         label: "Correo electrónico",
//         placeholder: "Correo electrónico",
//       },
//       password: {
//         label: "Contraseña",
//         placeholder: "Contraseña",
//       },
//       confirm_password: {
//         label: "Confirmar contraseña",
//         placeholder: "Confirmar contraseña",
//       },
//       given_name: {
//         label: "Nombres",
//         placeholder: "Nombres",
//       },
//       family_name: {
//         label: "Apellidos",
//         placeholder: "Apellidos",
//       },
//       birthdate: {
//         label: "Fecha de nacimiento",
//         placeholder: "Fecha de nacimiento",
//       },
//     },
//     resetPassword: {
//       username: {
//         label: "Correo electrónico",
//         placeholder: "Correo electrónico",
//       },
//     },
//     confirmResetPassword: {
//       confirmation_code: {
//         label: "Código de confirmación",
//         placeholder: "Código de confirmación",
//       },
//       password: {
//         label: "Contraseña",
//         placeholder: "Contraseña",
//       },
//       confirm_password: {
//         label: "Confirmar contraseña",
//         placeholder: "Confirmar contraseña",
//       },
//     },
//   },
//   components: {
//     Header: AuthHeader,
//     Footer: AuthFooter,
//   },
// } as WithAuthenticatorOptions);
// const AppWithAuthAndFormattedRUT = withRUTFormatter(AppWithAuth);
// export { AppWithAuthAndFormattedRUT as App };

const formFields: AuthenticatorProps["formFields"] = {
  signIn: {
    username: {
      label: "Correo electrónico",
      placeholder: "Correo electrónico",
    },
    password: {
      label: "Contraseña",
      placeholder: "Contraseña",
    },
  },
  signUp: {
    "custom:documentNumber": {
      label: "RUT",
      placeholder: "RUT",
      order: 1,
    },
    given_name: {
      label: "Nombres",
      placeholder: "Nombres",
      order: 2,
    },
    family_name: {
      label: "Apellidos",
      placeholder: "Apellidos",
      order: 3,
    },
    email: {
      label: "Correo electrónico",
      placeholder: "Correo electrónico",
      order: 4,
    },
    phone_number: {
      label: "Número de télefono",
      placeholder: "9 1234 5678",
      isRequired: true,
      dialCode: "+56",
      order: 5,
    },
    birthdate: {
      label: "Fecha de nacimiento",
      placeholder: "Fecha de nacimiento",
      order: 6,
    },
    password: {
      label: "Contraseña",
      placeholder: "Contraseña",
      order: 7,
    },
    confirm_password: {
      label: "Confirmar contraseña",
      placeholder: "Confirmar contraseña",
      order: 8,
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      label: "Código de confirmación",
      placeholder: "Código de confirmación",
    },
    password: {
      label: "Contraseña",
      placeholder: "Contraseña",
    },
    confirm_password: {
      label: "Confirmar contraseña",
      placeholder: "Confirmar contraseña",
    },
  },
};

const components = {
  Header: () => <div className="rounded-t-3xl bg-primary py-8"></div>,
  Footer: () => <div className="rounded-b-3xl bg-primary py-8"></div>,
};

const LoginPage = () => {
  const { route } = useAuthenticator((context) => [context.route]);
  const navigate = useNavigate();

  useEffect(() => {
    if (route === "authenticated") navigate("/", { replace: true });
  }, [route, navigate]);

  return (
    <div className="flex items-center justify-center py-10">
      <Authenticator
        initialState="signIn"
        formFields={formFields}
        components={components}
      ></Authenticator>
    </div>
  );
};

export { LoginPage };
