import {
  NewTransactionSignee,
  useTransactionStore,
} from "@/stores/TransactionStore";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faClose,
} from "@fortawesome/free-solid-svg-icons";

import { useNavigate, useParams } from "react-router-dom";
import { useAutoAnimate } from "@formkit/auto-animate/react";

import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";

import { Heading } from "@/components/Heading";
import { Fieldset, Field } from "@/components/Fieldset";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { AddSigneeForm } from "@/components/Transactions/AddSigneeForm";
import { DocumentAttachmentsCard } from "@/components/Transactions/DocumentAttachmentsCard";
import { useNotifications } from "@/hooks/useNotifications";
import imagenCarnet from "@/assets/images/carnet.jpg";
import imagenCarnetRUT from "@/assets/images/carnetRUT.jpg";
import imagenCarnetNombres from "@/assets/images/carnetNombres.jpg";
import imagenCarnetApellidos from "@/assets/images/carnetApellidos.jpg";
import { useState } from "react";
import { Separator } from "@/components/ui/separator";
import { formatearRut } from "@/helpers/rut.helpers";

const useCurrentDocument = () => {
  const params = useParams();

  return useTransactionStore((state) => {
    let currentDocument;
    let prevDocumentId;
    let nextDocumentId;

    for (const doc of state.documents) {
      if (currentDocument && !nextDocumentId) {
        nextDocumentId = doc.id;
      }

      if (doc.id === params.id) {
        currentDocument = doc;
      }

      if (!prevDocumentId && !currentDocument) {
        prevDocumentId = doc.id;
      }
    }

    return { currentDocument, prevDocumentId, nextDocumentId };
  });
};

type SigneesListProps = {
  signees: NewTransactionSignee[];
  onRemove: (signee: NewTransactionSignee) => void;
};

const SigneesList = ({ signees, onRemove }: SigneesListProps) => {
  const [parent] = useAutoAnimate();

  return (
    <div ref={parent}>
      {signees.map((signee, _key) => (
        <div
          key={_key}
          className="border-secondary-300 hover:bg-secondary-100 flex items-center justify-between border-b px-4 py-2 last:rounded-b last:border-0"
        >
          <div className="text-sm">
            <div className="text-base font-bold">
              {signee.firstName} {signee.lastName} {signee.maternalLastName}
            </div>
            <div className="text-primary-600">{signee.email}</div>
            <div className="text-secondary-600 space-x-2">
              <span>{formatearRut(signee.documentNumber)}</span>
              <span>{signee.country}</span>
            </div>
            {!!signee.inRepresentationOf && (
              <div>
                <p className="text-secondary-900">
                  En representación de{" "}
                  <span className="text-primary-700 text-base">
                    {signee.inRepresentationOf.name}
                  </span>
                  <span> - RUT: </span>
                  <span className="text-primary-700 text-base">
                    {formatearRut(signee.inRepresentationOf.documentNumber)}
                  </span>
                </p>
              </div>
            )}
          </div>
          <div>
            <Button variant="destructive" onClick={() => onRemove(signee)}>
              <FontAwesomeIcon icon={faClose} className="mr-2" />
              <span className="hidden md:inline">Eliminar</span>
            </Button>
          </div>
        </div>
      ))}

      {signees.length === 0 ? (
        <div className="text-secondary-500 p-8 text-center">
          Agrega al menos un firmante para continuar con el proceso
        </div>
      ) : null}
    </div>
  );
};

const AddSigneesPage = () => {
  const [selectedInput, setSelectedInput] = useState<
    "documentNumber" | "firstName" | "lastName" | "maternalLastName" | ""
  >("");

  const carnetImageMapper = (input: string) => {
    switch (input) {
      case "documentNumber":
        return imagenCarnetRUT;
      case "firstName":
        return imagenCarnetNombres;
      case "lastName":
      case "maternalLastName":
        return imagenCarnetApellidos;
      default:
        return imagenCarnet;
    }
  };

  const store = useTransactionStore();
  const navigate = useNavigate();
  const notifications = useNotifications();

  const { currentDocument, prevDocumentId, nextDocumentId } =
    useCurrentDocument();

  const currentSignees = currentDocument?.signees ?? [];
  const attachments = currentDocument?.attachments ?? [];

  const isDuplicateSignee = (newSignee: NewTransactionSignee) => {
    return currentSignees.some((signee) => {
      if (!!newSignee.inRepresentationOf !== !!signee.inRepresentationOf) {
        return false;
      }
      if (!!newSignee.inRepresentationOf && !!signee.inRepresentationOf) {
        return (
          newSignee.inRepresentationOf.documentNumber ===
            signee.inRepresentationOf.documentNumber &&
          newSignee.documentNumber === signee.documentNumber
        );
      }
      return newSignee.documentNumber === signee.documentNumber;
    });
  };

  if (!currentDocument) {
    navigate("/not-found");
    return null;
  }

  return (
    <div className="space-y-3">
      <Heading title="Agregar anexos y firmantes"></Heading>

      <Card>
        <CardHeader>
          <CardTitle className="text-lg">{currentDocument.title}</CardTitle>
        </CardHeader>

        <Separator className="mb-6" />

        <CardContent>
          <Fieldset>
            <Field
              label="Categoría"
              value={currentDocument.subcategory ?? ""}
            />
            <Field
              label="Descripción"
              value={currentDocument.description ?? "Sin descripción"}
            />
          </Fieldset>
        </CardContent>
      </Card>

      <DocumentAttachmentsCard
        attachments={attachments}
        document={currentDocument}
        onAdd={(items) => {
          store.addAttachments(currentDocument.id, items);
        }}
        onRemove={(attachmentId) => {
          store.removeAttachment(currentDocument.id, attachmentId);
        }}
      />

      <div className="grid gap-3 xl:grid-cols-12">
        <Card className="col-span-7">
          <CardHeader>Agregar Firmantes</CardHeader>

          <Separator className="mb-6" />

          <CardContent className="w-full">
            <AddSigneeForm
              onSubmit={(data, reset) => {
                if (isDuplicateSignee(data)) {
                  //alert("A signee with this document number already exists.");
                  notifications.warn("Ya existe un firmante con el mismo RUT");
                  return;
                }

                store.addSignee(currentDocument.id, {
                  ...data,
                  documentNumber: data.documentNumber.replace(/\./g, ""),
                });
                reset();
              }}
              setSelectedInput={setSelectedInput}
            />
          </CardContent>
        </Card>

        <Card className="col-span-5 hidden xl:block">
          <CardHeader>Ejemplo de cédula de identidad</CardHeader>

          <Separator className="mb-6" />

          <CardContent className="flex flex-col items-stretch justify-center">
            <div className="mt-10">
              <img
                src={carnetImageMapper(selectedInput ?? "")}
                className="w-full self-center object-cover"
                alt="Carnet de Identidad"
              />
            </div>
          </CardContent>
        </Card>
      </div>

      <Card>
        <CardHeader>
          <div className="flex items-center justify-between">
            <div>Firmantes</div>
            <div>
              <Badge>{currentSignees.length}</Badge>
            </div>
          </div>
        </CardHeader>

        <Separator className="mb-0" />

        <SigneesList
          signees={currentSignees}
          onRemove={(signee: NewTransactionSignee) =>
            store.removeSignee(currentDocument.id, signee)
          }
        />
      </Card>

      <div className="flex items-center justify-between gap-3">
        <div>
          <Button
            variant="outline"
            className="w-full sm:w-auto"
            size="lg"
            onClick={() => {
              if (prevDocumentId) {
                navigate(`/transactions/new/${prevDocumentId}/add-signees`);
              } else {
                navigate("/transactions/new/pick-files");
              }
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} size="xl" className="mr-2" />
            <span className="hidden md:inline">
              {prevDocumentId ? "Documento anterior" : "Agregar más documentos"}
            </span>
          </Button>
        </div>

        <div>
          <Button
            className="w-full sm:w-auto"
            size="lg"
            disabled={currentSignees.length === 0}
            onClick={() => {
              if (nextDocumentId) {
                navigate(`/transactions/new/${nextDocumentId}/add-signees`);
              } else {
                navigate("/transactions/new/review");
              }
            }}
          >
            <span>
              {nextDocumentId ? "Siguiente documento" : "Asignar notario"}
            </span>
            <FontAwesomeIcon icon={faArrowRight} size="xl" className="ml-2" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export { AddSigneesPage };
