import { apiClient, getSession } from "./utils";
import { LegalEntityUserRoleEnum } from "@/api/legal-entities";

export type User = {
  id: string;
  subId: string;
  email: string;
  firstName: string;
  lastName: string;
  documentNumber: string;
  country: string;
  legalEntities: {
    documentNumber: string;
    id: string;
    name: string;
    role: LegalEntityUserRoleEnum;
  }[];
  phoneNumber: string;
};

export const getCurrentUser = async () => {
  const { sub } = await getSession();
  return apiClient.get<User>(`/users/${sub}`).then((res) => res.data);
};
