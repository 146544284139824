import { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import { useAppStore } from "@/stores/AppStore";
import { Drawer, DrawerClose, DrawerContent } from "@/components/ui/drawer";

import {
  faHouse,
  faBuilding,
  faIdBadge,
  faSignOut,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, buttonVariants } from "./ui/button";
import { XIcon } from "lucide-react";
import { cn } from "@/lib/utils";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useTransactionStore } from "@/stores/TransactionStore";

const NavItem = ({ to, children }: { to: string; children: ReactNode }) => {
  return (
    <NavLink
      end
      to={to}
      className={({ isActive }) =>
        cn(
          buttonVariants({ variant: "ghost" }),
          "w-full justify-start py-6 text-lg",
          isActive ? "bg-accent" : "",
        )
      }
    >
      {children}
    </NavLink>
  );
};

const DrawerMenu = () => {
  const { signOut } = useAuthenticator();
  const { user, closeDrawer } = useAppStore();

  return (
    <div className="h-screen w-full">
      <div className="p-4 text-center">
        <div className="font-bold">
          {user?.firstName} {user?.lastName}
        </div>
        <div className="text-sm">{user?.email}</div>
      </div>

      <ul className="space-y-4 px-6">
        <li>
          <NavItem to="/">
            <FontAwesomeIcon icon={faHouse} className="mr-2 w-5" />
            <span>Inicio</span>
          </NavItem>
        </li>

        <li>
          <NavItem to="/profile">
            <FontAwesomeIcon icon={faIdBadge} className="mr-2 w-5" />
            <span>Mi perfil</span>
          </NavItem>
        </li>

        <li>
          <NavItem to="/legal-entities">
            <FontAwesomeIcon icon={faBuilding} className="mr-2 w-5" />
            <span>Mis empresas</span>
          </NavItem>
        </li>

        <li>
          <Button
            onClick={() => {
              closeDrawer();
              useAppStore.getState().clear();
              useTransactionStore.getState().reset();
              signOut();
            }}
            variant="ghost"
            className={cn(
              buttonVariants({ variant: "ghost" }),
              "w-full justify-start py-6 text-lg",
            )}
          >
            <FontAwesomeIcon icon={faSignOut} className="mr-2 w-5" />
            <span>Cerrar sesión</span>
          </Button>
        </li>
      </ul>
    </div>
  );
};

const Sidebar = () => {
  const { isDrawerOpen, closeDrawer } = useAppStore();

  return (
    <Drawer open={isDrawerOpen} direction="left" onClose={closeDrawer}>
      <DrawerContent className="fixed bottom-0 left-0 flex h-full w-[350px] flex-col rounded-none">
        <DrawerClose
          className="absolute right-2 top-4 pr-4"
          onClick={closeDrawer}
        >
          <XIcon />
        </DrawerClose>

        <DrawerMenu />
      </DrawerContent>
    </Drawer>
  );
};

export { Sidebar };
