import React from "react";
import { Input } from "./ui/input";

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  hasError?: boolean;
};

type FormProps = React.FormHTMLAttributes<HTMLFormElement> & {}; // eslint-disable-line

const FormInput = React.forwardRef(
  (
    { hasError, ...props }: InputProps,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    let className = `
    block
    ${props.type === "checkbox" ? "rounded cursor-pointer" : "rounded"}
    ${hasError ? "border-danger-500" : "border-secondary-300"}
    focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50
    disabled:bg-secondary-100
  `;

    if (props.className) {
      className = `${props.className} ${className}`;
    }

    return <Input {...props} className={className} ref={ref} />;
  },
);

const FormSelect = (
  props: React.SelectHTMLAttributes<HTMLSelectElement> & {
    reference?: React.ForwardedRef<HTMLSelectElement>;
  },
) => {
  let className = `
    cursor-pointer
    block
    rounded
    border-secondary-300
    shadow
    focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50
  `;

  if (props.className) {
    className = `${props.className} ${className}`;
  }

  return (
    <select ref={props.reference} {...props} className={className}>
      {props.children}
    </select>
  );
};

const FormTextarea = (
  props: React.TextareaHTMLAttributes<HTMLTextAreaElement>,
) => {
  let className = `
    block
    rounded
    border-secondary-300
    shadow
    focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50
  `;

  if (props.className) {
    className = `${props.className} ${className}`;
  }

  return <textarea {...props} className={className}></textarea>;
};

const Form = ({ children, ...props }: FormProps) => {
  return <form {...props}>{children}</form>;
};

FormInput.displayName = "Input";

export { Form, FormInput, FormSelect, FormTextarea };
