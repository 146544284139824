import { useMachine } from "@xstate/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAppStore } from "@/stores/AppStore";
import { useTransactionStore } from "@/stores/TransactionStore";
import { transactionMachine } from "@/stores/TransactionMachine";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";

import { Heading } from "@/components/Heading";
import { Button } from "@/components/ui/button";
import { TransactionReviewCard } from "@/components/Transactions/TransactionReviewCard";
import { NotarySelectorCard } from "./NotarySelectorCard";
import { ProcedureTypeEnum } from "@/enums/ProcedureType.enum";

const TransactionReviewPage = () => {
  const app = useAppStore();
  const store = useTransactionStore();
  const navigate = useNavigate();

  const [machineState, machineSend] = useMachine(transactionMachine, {
    context: {
      subId: app.user?.subId,
      legalEntityDocumentNumber: app.legalEntity?.documentNumber,
    },
  });

  useEffect(() => {
    if (store.documents.length === 0) {
      navigate("/transactions/new");
    }
  }, [navigate]);

  const isRunning = machineState.value.toString().endsWith("IN_PROGRESS");
  const isError = machineState.value.toString().endsWith("FAILED");
  const procedureType = store.documents[0].procedureType as ProcedureTypeEnum;

  useEffect(() => {
    if (machineState.value === "CREATE_TRANSACTION_COMPLETE") {
      const firstDoc = store.documents[0];
      navigate(
        `/transactions/${store.id}/documents/${firstDoc.documentCode}/signatures`,
      );
      store.reset();
    }
  }, [machineState]);

  return (
    <div className="space-y-3">
      <Heading title="Seleccionar notario y subir documentos" />

      <NotarySelectorCard
        notary={store.notary}
        procedureType={procedureType}
        onChange={(notary) => store.setNotary(notary)}
      />

      <TransactionReviewCard store={store} />

      <div className="flex items-center justify-between gap-3">
        <div>
          <Button
            variant="outline"
            size="lg"
            onClick={() => {
              const document = store.documents[store.documents.length - 1];

              if (document) {
                navigate(`/transactions/new/${document.id}/add-signees`);
              }
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} size="xl" className="mr-2" />
            <span className="hidden md:inline">Volver</span>
          </Button>
        </div>

        <div className="grow gap-3 md:flex md:grow-0 md:items-center">
          <div className="hidden md:block">
            {isError
              ? machineState.context.error ??
                "Hubo un error al crear los documentos"
              : null}
          </div>

          <Button
            className={`w-full md:w-auto ${isRunning ? "animate-pulse" : ""}`}
            variant="default"
            size="lg"
            disabled={!store.notary || isRunning}
            onClick={() => {
              if (app.user && app.legalEntity) {
                machineSend({
                  type: machineState.nextEvents[0],
                });
              }
            }}
          >
            {isRunning ? (
              <>
                <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />
                <span>Creando documentos</span>
              </>
            ) : (
              <>
                <span>{isError ? "Reintentar" : "Continuar"}</span>
                <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
              </>
            )}
          </Button>
        </div>
      </div>

      <div className="text-center md:hidden">
        <div>{isError ? "Hubo un error al crear los documentos" : null}</div>
      </div>
    </div>
  );
};

export { TransactionReviewPage };
