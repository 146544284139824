import axios from "axios";

import { useAppStore } from "@/stores/AppStore";
import { apiClient } from "./utils.ts";
import { ProcedureTypeEnum } from "@/enums/ProcedureType.enum.ts";
import { TransactionDocument } from "../types/Document.type.ts";

type CreateDocumentsArgs = {
  subId: string;
  documents: {
    category: string;
    subcategory: string;
    name: string;
    ownerSigns: boolean;
    legalEntityDocumentNumber: string;
    isNotarized: boolean;
    needsVideo: boolean;
    contentType: string;
    supportingDocuments: {
      code: string;
      name: string;
      subcategory: string;
      contentType: string;
    }[];
    isProtocolized: boolean;
    procedureType: ProcedureTypeEnum;
  }[];
};

type CreatedDocument = {
  id: string;
  code: string;
  name: string;
  category: string;
  subcategory: string;
  signees: {}[]; // eslint-disable-line
  status: string;
  supportingDocuments: {
    code: string;
    name: string;
    contentType: string;
  }[];
  ownerSigns: boolean;
  isArchived: boolean;
  isNotarized: boolean;
  hasAnnotations: boolean;
  needsVideo: boolean;
  contentType: string;
  legalEntity: {
    id: string;
    name: string;
    documentNumber: string;
  };
  user: {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
  };
  isProtocolized: boolean;
  procedureType: ProcedureTypeEnum;
  createdAt: string;
  updatedAt: string;
};

type AddSigneeArgs = {
  documentCode: string;
  documentNumber: string;
  firstName: string;
  lastName: string;
  email: string;
  countryCode: string;
};

type AddSigneeResponse = {}; // eslint-disable-line

type UpdateSigneeArgs = {
  documentCode: string;
  documentNumber: string;
  geolocalization?: {
    latitude: string;
    longitude: string;
  };
  coordinatesPdf?: {
    code: string;
    x: number;
    y: number;
    page: number;
  }[];
  documentNumberCompany?: string;
};

type GetDocumentsFilters = {
  transactionId?: string;
  isArchived?: boolean;
  itemsPerPage?: number;
  pageNumber?: number;
};

export const getCategories = async () => {
  return apiClient
    .get<{
      categories: string[];
      subcategories: string[];
    }>(`/categories`)
    .then((res) => res.data);
};

export const getDocuments = async (filters: GetDocumentsFilters) => {
  const { legalEntity } = useAppStore.getState();

  if (!legalEntity) {
    throw new Error("No legal entity");
  }

  if (!filters.itemsPerPage) {
    filters.itemsPerPage = 50;
  }

  return apiClient
    .get<TransactionDocument[]>(
      `/legal-entities/${legalEntity?.documentNumber}/documents`,
      {
        params: filters,
      },
    )
    .then((res) => res.data);
};

export const getDocumentByCode = async (documentCode: string) => {
  return apiClient
    .get<TransactionDocument>(`/documents/${documentCode}`)
    .then((res) => res.data);
};

export const getDocumentDownloadUrl = async (
  documentId: string,
  kind: string,
) => {
  const fileIdEncoded = encodeURIComponent(`${documentId}/${kind}.pdf`);

  return apiClient
    .get<string>(`/files/${fileIdEncoded}/DOCUMENT`)
    .then((res) => res.data);
};

export const createDocuments = async (data: CreateDocumentsArgs) => {
  return apiClient
    .post<CreatedDocument[]>(`/documents`, data)
    .then((res) => res.data);
};

export const updateDocument = async (data: {
  documentCode: string;
  status: string;
}) => {
  return apiClient
    .put(`/documents/${data.documentCode}`, {
      status: data.status,
    })
    .then((res) => res.data);
};

export const uploadDocumentFile = async (data: {
  documentId: string;
  fileType: "DOCUMENT" | "ATTACHMENT" | "LEGAL_CONSTITUTION";
  fileKey: string;
  contents: File;
}) => {
  const payload = data.contents;

  return apiClient
    .post<string>(`/files`, {
      documentId: data.documentId,
      fileType: data.fileType,
      fileKey: data.fileKey,
    })
    .then((res) => axios.put(res.data, payload));
};

export const addSignee = async (data: AddSigneeArgs) => {
  return apiClient
    .post<AddSigneeResponse>(`/signees`, data)
    .then((res) => res.data);
};

export const updateSignee = async (data: UpdateSigneeArgs) => {
  return apiClient
    .put(
      `/documents/${data.documentCode}/signees/${data.documentNumber}/update`,
      data,
    )
    .then((res) => res.data);
};
