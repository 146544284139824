import { useQuery } from "@tanstack/react-query";

import { listDocuments } from "@/api/v2/documents";
import { useAppStore } from "@/stores/AppStore";
import { DocumentsList } from "@/components/Documents/DocumentsList";

const DocumentsPage = () => {
  const { legalEntity } = useAppStore();

  const query = useQuery({
    queryKey: [
      "listDocuments",
      {
        legalEntityId: legalEntity?.id,
        pageSize: 500,
      },
    ],
    queryFn: () =>
      listDocuments({
        legalEntityId: legalEntity?.id as string,
        pageSize: 500,
      }),
    enabled: !!legalEntity,
  });

  const documents = query.data?.items ?? [];

  return (
    <div>
      <DocumentsList data={documents} isLoading={query.isLoading} />
    </div>
  );
};

export { DocumentsPage };
