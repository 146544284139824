import Axios from "axios";
import { fetchAuthSession } from "aws-amplify/auth";

const PUBLIC_API_URL = import.meta.env.VITE_PUBLIC_API_URL;
const FIRME_API_URL = import.meta.env.VITE_FIRME_API_URL;

export const apiClient = Axios.create({
  baseURL: PUBLIC_API_URL,
});

apiClient.interceptors.request.use(
  async (config) => {
    try {
      const session = await fetchAuthSession();
      const token = session.tokens?.idToken?.toString();

      if (token) {
        config.headers.setAuthorization(token);
      }
    } catch (e) {
      console.error(e);
    }
    return config;
  },
  (error) => Promise.reject(error),
);

export const getSession = async () => {
  const session = await fetchAuthSession();
  const token = session.tokens?.idToken?.toString();
  return { token, sub: session.userSub };
};

export const firmeClient = Axios.create({
  baseURL: FIRME_API_URL,
});

firmeClient.interceptors.request.use(
  async (config) => {
    try {
      const session = await fetchAuthSession();
      const token = session.tokens?.idToken?.toString();
      config.headers.setAuthorization(`Bearer ${token}`);
    } catch (e) {
      console.error(e);
    }
    return config;
  },
  (error) => Promise.reject(error),
);
