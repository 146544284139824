import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { I18n } from "aws-amplify/utils";
import { Amplify } from "aws-amplify";
import { Authenticator, translations } from "@aws-amplify/ui-react";
import { ThemeProvider } from "@/components/theme-provider";
import { RouterProvider } from "react-router-dom";
import { Toaster } from "sonner";

import router from "./routes";
import config from "./amplifyconfiguration.json";

Amplify.configure(config);

const queryClient = new QueryClient();

I18n.setLanguage("es");
I18n.putVocabularies(translations);
I18n.putVocabularies({
  es: {
    "Sign in": "Iniciar sesión",
    "Forgot your password?": "¿Olvidaste tu contraseña?",
    "Back to Sign In": "Volver a iniciar sesión",
    "Sign In": "Iniciar sesión",
    "Create Account": "Crear cuenta",
    "Send code": "Enviar código",
    "Reset Password": "Restablecer contraseña",
    Sending: "Enviando",
    "Resend Code": "Reenviar código",
    Submit: "Enviar",
    "Attempt limit exceeded, please try after some time.":
      "Límite de intentos excedido, por favor intente después de un tiempo.",
    "Password must have at least 8 characters":
      "La contraseña debe tener al menos 8 caracteres",
    "Password must have numbers": "La contraseña debe tener números",
    "Password must have special characters":
      "La contraseña debe tener caracteres especiales",
    "Password must have upper case letters":
      "La contraseña debe tener letras mayúsculas",
    "Your passwords must match": "Las contraseñas deben coincidir",
    "We Emailed You": "Te enviamos un correo electrónico",
    "Your code is on the way. To log in, enter the code we emailed to":
      "Tu código está en camino. Para iniciar sesión, ingresa el código que te enviamos por correo electrónico a",
    "It may take a minute to arrive": "Puede tardar un minuto en llegar",
    Confirm: "Confirmar",
    "Enter your code": "Ingresa tu código",
    "Confirmation Code": "Código de confirmación",
    "Invalid verification code provided, please try again.":
      "Código de verificación no válido, por favor intente nuevamente.",
    Confirming: "Confirmando",
    Submitting: "Enviando",
    "Username/client id combination not found.":
      "Correo electrónico no encontrado.",
    "Creating Account": "Creando cuenta",
  },
});

function App() {
  return (
    <Authenticator.Provider>
      <ThemeProvider defaultTheme="light" storageKey="ui-theme">
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <RouterProvider router={router} />
        </QueryClientProvider>
        <Toaster />
      </ThemeProvider>
    </Authenticator.Provider>
  );
}

export { App };
