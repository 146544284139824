import { firmeClient, apiClient, getSession } from "./utils";

export enum LegalEntityUserRoleEnum {
  LegalEntityAdministrator = "LEGAL_ENTITY_ADMINISTRATOR",
  LegalEntityAssistant = "LEGAL_ENTITY_ASSISTANT",
  LegalRepresentative = "LEGAL_REPRESENTATIVE",
}

export enum LegalEntityUserRoleMapper {
  LEGAL_ENTITY_ADMINISTRATOR = "Administrador",
  LEGAL_ENTITY_ASSISTANT = "Asistente",
  LEGAL_REPRESENTATIVE = "Representante Legal",
}

export enum PersonTypeEnum {
  JURIDICAL = "JURIDICAL",
  NATURAL = "NATURAL",
}

export type LegalRepresentative = {
  country: string;
  documentNumber: string;
  email: string;
  firstName: string;
  lastName: string;
  role: LegalEntityUserRoleEnum;
};

export type LegalEntityUser = {
  subId: string;
  email: string;
  firstName: string;
  lastName: string;
  documentNumber: string;
  role: LegalEntityUserRoleEnum;
};

export type LegalEntity = {
  id: string;
  name: string;
  documentNumber: string;
  role: LegalEntityUserRoleEnum;
  personType: PersonTypeEnum;
  createdAt: string;
  updatedAt: string;
  legalRepresentatives: LegalRepresentative[];
  users?: LegalEntityUser[];
};

export type LegalRepresentativeItemResponse = {
  firstName: string;
  lastName: string;
  maternalLastName: string | null;
  email: string;
  documentNumber: string;
  phoneNumber?: string | null;
  country: string;
};

export const listLegalEntities = async ({
  userDocumentNumber,
}: {
  userDocumentNumber: string;
}) => {
  return await apiClient
    .get<LegalEntity[]>(`/users/legal-entity/${userDocumentNumber}`)
    .then((res) => res.data);
};

export const addUser = async (data: {
  documentNumber: string;
  name?: string;
  email: string;
  role: string;
  legalEntity: LegalEntity;
}) => {
  return await apiClient
    .post(`/legal-entities/add`, {
      documentNumber: data.legalEntity.documentNumber,
      userRepresentatives: [
        {
          name: data.name,
          documentNumber: data.documentNumber,
          email: data.email,
          legalEntityUserRole: data.role,
        },
      ],
    })
    .then((res) => {
      return res.data as {
        users: LegalEntityUser[];
        legalRepresentatives: LegalRepresentative[];
      };
    });
};

export const createLegalEntity = async (data: {
  name: string;
  economicActivity: string;
  businessCategory: string;
  documentNumber: string;
  address: {
    country: "CHILE";
    region: string;
    municipality: string;
    street: string;
    streetNumber: string;
    detail?: string;
  };
}) => {
  const { sub } = await getSession();
  return await apiClient.post("/legal-entities/inscribe", {
    ...data,
    subId: sub,
  });
};

export const getQuota = async ({
  documentNumber,
}: {
  documentNumber: string;
}) => {
  return await apiClient
    .get<{
      hasPreference: boolean;
      documentsQuota?: DocumentQuota;
    }>(`/legal-entities/${documentNumber}/documents-quota`)
    .then((res) => res.data);
};

export const listLabels = async ({
  legalEntityId,
}: {
  legalEntityId: string;
}) =>
  await firmeClient
    .get<string[]>(`/legal-entities/${legalEntityId}/labels`)
    .then((res) => res.data);

export const createLabel = async ({
  legalEntityId,
  name,
}: {
  legalEntityId: string;
  name: string;
}) =>
  await firmeClient
    .post<string[]>(`/legal-entities/${legalEntityId}/labels`, { name })
    .then((res) => res.data);

export const deleteLabel = async ({
  legalEntityId,
  name,
}: {
  legalEntityId: string;
  name: string;
}) =>
  await firmeClient
    .delete<
      string[]
    >(`/legal-entities/${legalEntityId}/labels/${encodeURIComponent(name)}`)
    .then((res) => res.data);

export const listLegalEntityRepresentatives = async ({
  legalEntityId,
}: {
  legalEntityId: string;
}) =>
  await firmeClient
    .get<
      LegalRepresentativeItemResponse[]
    >(`/legal-entities/${legalEntityId}/representatives`)
    .then((res) => res.data);
