import { useAuthenticator } from "@aws-amplify/ui-react";
import * as Sentry from "@sentry/react";

import { Navigate, Outlet, createBrowserRouter } from "react-router-dom";

import { ErrorPage } from "./pages/ErrorPage";
import { NotFoundPage } from "./pages/NotFoundPage";
import { RootLayout } from "./layouts/RootLayout";
import { AppLayout } from "./layouts/AppLayout";
import { LegalEntityLayout } from "./layouts/LegalEntityLayout";
import { WideLayout } from "./layouts/WideLayout";

import { LoginPage } from "./pages/LoginPage";
import { DocumentsPage } from "./pages/Documents/DocumentsPage";
import { PickFilesPage } from "./pages/Transactions/PickFilesPage";
import { AddSigneesPage } from "./pages/Transactions/AddSigneesPage";
import { TransactionReviewPage } from "./pages/Transactions/TransactionReviewPage";
import { AlertOctagon } from "lucide-react";
import { Button } from "./components/ui/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";

const RequiresAuth = () => {
  const { route } = useAuthenticator((context) => [context.route]);

  if (route === "authenticated") {
    return <Outlet />;
  }

  return <LoginPage />;
};

const ErrorMessage = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-6 rounded-md bg-muted/80 p-10">
      <h1 className="text-2xl">Ha ocurrido un error inesperado</h1>

      <AlertOctagon height="100px" width="100%" />

      <div>
        <Button onClick={() => window.location.reload()}>
          <FontAwesomeIcon icon={faRefresh} className="mr-3" />
          <span>Recargar</span>
        </Button>
      </div>
    </div>
  );
};

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        element: <RequiresAuth />,
        children: [
          {
            element: <AppLayout />,
            children: [
              {
                index: true,
                errorElement: <ErrorMessage />,
                element: <DocumentsPage />,
              },
              {
                path: "/transactions",
                children: [
                  {
                    path: "new",
                    errorElement: <ErrorMessage />,
                    element: <Navigate to="/transactions/new/pick-files" />,
                  },
                  {
                    path: "new/pick-files",
                    errorElement: <ErrorMessage />,
                    element: <PickFilesPage />,
                  },
                  {
                    path: "new/:id/add-signees",
                    errorElement: <ErrorMessage />,
                    element: <AddSigneesPage />,
                  },
                  {
                    path: "new/review",
                    errorElement: <ErrorMessage />,
                    element: <TransactionReviewPage />,
                  },
                  {
                    path: ":transactionId/summary",
                    errorElement: <ErrorMessage />,
                    async lazy() {
                      const { TransactionSummaryPage } = await import(
                        "@/pages/Transactions/TransactionSummaryPage"
                      );
                      return { Component: TransactionSummaryPage };
                    },
                  },
                ],
              },
              {
                path: "/documents",
                children: [
                  {
                    index: true,
                    element: <Navigate to="/" />,
                  },
                  {
                    path: ":code",
                    errorElement: <ErrorMessage />,
                    async lazy() {
                      const { DocumentDetailsPage } = await import(
                        "@/pages/Documents/DocumentsDetailPage"
                      );
                      return { Component: DocumentDetailsPage };
                    },
                  },
                  {
                    path: ":documentCode/edit",
                    errorElement: <ErrorMessage />,
                    async lazy() {
                      const { DocumentModificationPage } = await import(
                        "@/pages/Documents/ModifyDocument/DocumentModificationPage"
                      );
                      return { Component: DocumentModificationPage };
                    },
                  },
                ],
              },
              {
                path: "/legal-entities",
                children: [
                  {
                    index: true,
                    errorElement: <ErrorMessage />,
                    async lazy() {
                      const { LegalEntitiesPage } = await import(
                        "@/pages/LegalEntities/LegalEntitiesPage"
                      );
                      return { Component: LegalEntitiesPage };
                    },
                  },
                  {
                    path: "new",
                    errorElement: <ErrorMessage />,
                    async lazy() {
                      const { EnrollLegalEntityPage } = await import(
                        "@/pages/LegalEntities/EnrollLegalEntityPage"
                      );
                      return { Component: EnrollLegalEntityPage };
                    },
                  },
                  {
                    path: ":legalEntityId",
                    element: <LegalEntityLayout />,
                    errorElement: <ErrorMessage />,
                    children: [
                      {
                        index: true,
                        errorElement: <ErrorMessage />,
                        async lazy() {
                          const { LegalEntityGeneralPage } = await import(
                            "@/pages/LegalEntities/LegalEntityGeneralPage"
                          );
                          return { Component: LegalEntityGeneralPage };
                        },
                      },
                      {
                        path: "users",
                        errorElement: <ErrorMessage />,
                        async lazy() {
                          const { LegalEntityUsersPage } = await import(
                            "@/pages/LegalEntities/LegalEntityUsersPage"
                          );
                          return { Component: LegalEntityUsersPage };
                        },
                      },
                      {
                        path: "representatives",
                        errorElement: <ErrorMessage />,
                        async lazy() {
                          const { LegalEntityRepresentativesPage } =
                            await import(
                              "@/pages/LegalEntities/LegalEntityRepresentativesPage"
                            );
                          return { Component: LegalEntityRepresentativesPage };
                        },
                      },
                      {
                        path: "credits",
                        errorElement: <ErrorMessage />,
                        async lazy() {
                          const { LegalEntityCreditsPage } = await import(
                            "@/pages/LegalEntities/LegalEntityCreditsPage"
                          );
                          return { Component: LegalEntityCreditsPage };
                        },
                      },
                      {
                        path: "labels",
                        errorElement: <ErrorMessage />,
                        async lazy() {
                          const { LegalEntityLabelsPage } = await import(
                            "@/pages/LegalEntities/LegalEntityLabelsPage"
                          );
                          return { Component: LegalEntityLabelsPage };
                        },
                      },
                    ],
                  },
                ],
              },
              {
                path: "/profile",
                errorElement: <ErrorMessage />,
                async lazy() {
                  const { ProfilePage } = await import(
                    "@/pages/Profile/ProfilePage"
                  );
                  return { Component: ProfilePage };
                },
              },
            ],
          },
        ],
      },
      {
        path: "/login",
        element: <AppLayout />,
        children: [
          {
            index: true,
            element: <LoginPage />,
          },
        ],
      },
      {
        path: "/transactions/:transactionId",
        element: <WideLayout />,
        children: [
          {
            path: "documents/:documentCode/signatures",
            errorElement: <ErrorMessage />,
            async lazy() {
              const { AddSignaturesPage } = await import(
                "@/pages/Documents/AddSignaturesPage"
              );
              return { Component: AddSignaturesPage };
            },
          },
        ],
      },
      {
        path: "not-found",
        children: [
          {
            index: true,
            element: <NotFoundPage />,
          },
        ],
      },
      {
        path: "*",
        element: <NotFoundPage />,
      },
    ],
  },
]);

export default router;
