import { DocumentStatus } from "@/types/Document.type";
import { Badge } from "@/components/ui/badge";
import { TDocumentStatus } from "@/api/v2/documents";

type DocumentStatusBadgeProps = {
  status: DocumentStatus | TDocumentStatus;
  isLoading?: boolean;
};

const statusMap = {
  DRAFT: "Borrador",
  PENDING_SIGNATURE: "Pendiente de firmas",
  PENDING_NOTARY_SIGNATURE: "Firma de notario pendiente",
  NOTARIZED: "Notarizado",
  MISSING_ATTACHMENT: "Falta adjunto",
  REPAIR: "Reparo",
  REJECTED: "Rechazado",
  CANCELLED: "Cancelado",
  COMPLETED: "Completado",
  REFUNDED: "Reembolsado",
};

const DocumentStatusBadge = ({ status }: DocumentStatusBadgeProps) => {
  return (
    <Badge className="whitespace-nowrap" variant="secondary">
      {statusMap[status]}
    </Badge>
  );
};

export { DocumentStatusBadge };
