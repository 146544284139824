import {
  ElementRef,
  ReactNode,
  createContext,
  forwardRef,
  useContext,
} from "react";

import { useAutoAnimate } from "@formkit/auto-animate/react";
import { cn } from "@/lib/utils";
import { Skeleton } from "./ui/skeleton";

type FieldErrorProps = {
  message?:
    | string
    | {
        _errors: string[];
      };
};

type FieldProps = {
  label?: string;
  isLoading?: boolean;
  className?: string;
  value?: string;
  children?: ReactNode;
  error?: FieldErrorProps["message"];
};

type FieldsetProps = {
  children?: ReactNode;
  isLoading?: boolean;
  animate?: boolean;
};

type FieldsetState = {
  isLoading?: boolean;
  animate?: boolean;
};

const FieldsetContext = createContext<FieldsetState>({
  isLoading: false,
  animate: false,
});

const FieldError = ({ message }: FieldErrorProps) => {
  if (!message) return null;

  if (typeof message === "string") {
    return <div className="">{message}</div>;
  } else {
    return (
      <div className="mt-1 text-destructive">
        {message?._errors.map((m, idx) => <div key={idx}>{m}</div>)}
      </div>
    );
  }
};

const Field = ({
  label,
  value,
  className,
  isLoading,
  children,
  error,
}: FieldProps) => {
  const [parent] = useAutoAnimate();
  const state = useContext(FieldsetContext);

  return (
    <div
      className={cn(
        "grid grid-cols-1 items-center items-center gap-2 lg:grid-cols-6 lg:gap-4",
        className,
      )}
    >
      <div className={cn("col-span-2 text-sm font-semibold")}>
        {state.isLoading || isLoading ? <Skeleton className="h-6" /> : label}
      </div>

      <div
        className={cn(
          "text-sm",
          state.isLoading || isLoading ? "col-span-1" : "col-span-4",
        )}
        ref={state.animate ? parent : null}
      >
        {children ? (
          children
        ) : state.isLoading || isLoading ? (
          <Skeleton className="h-6" />
        ) : (
          value
        )}

        <FieldError message={error} />
      </div>
    </div>
  );
};

const Fieldset = forwardRef<ElementRef<"div">, FieldsetProps>(
  ({ children, isLoading, animate = false }, ref) => {
    return (
      <FieldsetContext.Provider value={{ isLoading, animate }}>
        <div ref={ref} className="grid grid-cols-1 gap-4">
          {children}
        </div>
      </FieldsetContext.Provider>
    );
  },
);

export { Fieldset, Field };
