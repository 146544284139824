import { v4 as uuidv4 } from "uuid";
import { useCallback, useMemo, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  NewTransactionDocument,
  useTransactionStore,
} from "@/stores/TransactionStore";

import {
  faArrowRight,
  faCircleCheck,
  faCircleExclamation,
  faClose,
  faCloudUpload,
  faPencil,
  faSave,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import { Heading } from "@/components/Heading";
import { Card, CardContent, CardFooter, CardTitle } from "@/components/ui/card";
import { Fieldset, Field } from "@/components/Fieldset";
import { Button } from "@/components/ui/button";
import { useQuery } from "@tanstack/react-query";
import { getCategories } from "@/api/documents";
import { useNavigate } from "react-router-dom";
import { ProcedureTypeEnum } from "@/enums/ProcedureType.enum";

import { toast } from "sonner";
import { Separator } from "@/components/ui/separator";
import { Input } from "@/components/ui/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import { Check, ChevronDown } from "lucide-react";
import { cn } from "@/lib/utils";
import { Checkbox } from "@/components/ui/checkbox";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";

const CATEGORIES_BLACKLIST = [
  "Poder Retiro de Vehículo",
  "Poder Solicitar Placas de Vehículos",
  "Poder Alterar Características de Vehículo",
  "Autorización de Trabajo Menores de Edad",
  "Autorización de Viaje para Menores",
  "Autorización de Menores para Conducir",
  "Transferencia de Vehículo",
  "Pagaré",
];

const useCategories = () => {
  const query = useQuery(["categories"], getCategories);
  const parents = query.data?.categories ?? [];

  const subcategories = useMemo(() => {
    const items = query.data?.subcategories ?? [];
    return items.filter((x) => !CATEGORIES_BLACKLIST.includes(x));
  }, [query.data]);

  const buildOptions = useCallback(() => {
    const categories: Record<string, string[]> = {};

    const others = [];

    for (const item of subcategories ?? []) {
      const parent = parents.find((x) => {
        const start = item.toLowerCase().slice(0, 5);
        return x.toLowerCase().startsWith(start);
      });

      if (!parent) {
        others.push(item);
      } else {
        if (!categories[parent]) {
          categories[parent] = [];
        }

        categories[parent].push(item);
      }
    }

    categories["OTROS"] = others;

    return Object.entries(categories).map((item) => {
      return {
        label: item[0].replace("_", " "),
        value: item[0],
        category: null,
        options: item[1].map((x) => ({
          label: x,
          value: x,
          category: item[0],
        })),
      };
    });
  }, [query.data]);

  return buildOptions();
};

const CategoriesSelector = ({
  defaultValue,
  onChange,
}: {
  defaultValue: {
    category: string;
    subcategory: string;
  };
  onChange: ({
    category,
    subcategory,
  }: {
    category: string;
    subcategory: string;
  }) => void;
}) => {
  const [open, setOpen] = useState(false);
  const options = useCategories();

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          className="w-full justify-between"
        >
          <span>
            {defaultValue.subcategory !== ""
              ? defaultValue.subcategory
              : "Seleccionar categoría"}
          </span>
          <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>

      <PopoverContent className="min-w-[600px] p-0" align="start">
        <Command
          filter={(value, search) => {
            if (
              value
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(
                  search
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, ""),
                )
            )
              return 1;
            return 0;
          }}
        >
          <CommandInput placeholder="Filtrar categorías" />
          <CommandEmpty>Categorías no encontradas</CommandEmpty>
          <CommandList>
            {options.map((group) => (
              <CommandGroup key={group.value} heading={group.label}>
                {group.options.map((item) => (
                  <CommandItem
                    key={item.value}
                    value={item.value}
                    className="cursor-pointer"
                    onSelect={(currentValue) => {
                      onChange({
                        category: group.value,
                        subcategory: currentValue,
                      });
                      setOpen(false);
                    }}
                  >
                    <Check
                      className={cn(
                        "mr-2 h-4 w-4",
                        defaultValue.subcategory === item.value
                          ? "opacity-100"
                          : "opacity-0",
                      )}
                    />

                    {item.label}
                  </CommandItem>
                ))}
              </CommandGroup>
            ))}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

const DocumentItemForm = ({
  document,
  onSave,
  onRemove,
}: {
  document: NewTransactionDocument;
  onSave: (data: NewTransactionDocument) => void;
  onRemove: (id: string) => void;
}) => {
  const [title, setTitle] = useState(document.title ?? "");
  const [category, setCategory] = useState(document.category ?? "");
  const [subcategory, setSubcategory] = useState(document.subcategory ?? "");
  const [description, setDescription] = useState(document.description ?? "");

  const [isProtocolized, setIsProtocolized] = useState<boolean>(
    document.isProtocolized ?? false,
  );

  const [procedureType, setProcedureType] = useState<ProcedureTypeEnum>(
    (document.procedureType as ProcedureTypeEnum | null) ??
      ProcedureTypeEnum.CERTIFY,
  );

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSave({
          ...document,
          title,
          description,
          category,
          subcategory,
          isProtocolized,
          procedureType,
        });
      }}
    >
      <CardContent>
        <Fieldset>
          <Field label="Nombre del documento">
            <Input
              type="text"
              className="w-full"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Field>

          <Field label="Categoría">
            <CategoriesSelector
              defaultValue={{
                subcategory,
                category,
              }}
              onChange={(selection) => {
                if (selection) {
                  setCategory(selection.category ?? "");
                  setSubcategory(selection.subcategory ?? "");
                }
              }}
            />
          </Field>

          <Field label="Descripción">
            <Input
              type="text"
              className="w-full"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Field>

          <Field label="Tipo de trámite">
            <RadioGroup
              defaultValue={procedureType}
              orientation="vertical"
              onValueChange={(value) =>
                setProcedureType(value as ProcedureTypeEnum)
              }
            >
              <div className="flex flex-col gap-4">
                <div>
                  <RadioGroupItem
                    value={ProcedureTypeEnum.CERTIFY}
                    className="text-secondary-500 mr-2 cursor-pointer text-xs font-bold"
                    id="certify"
                  />
                  <Label htmlFor="certify" className="cursor-pointer">
                    Certificación de documento
                  </Label>
                </div>

                <div className="flex gap-3">
                  <div>
                    <RadioGroupItem
                      value={ProcedureTypeEnum.AUTHORIZE}
                      className={cn(
                        "text-secondary-500 mr-2 cursor-pointer text-xs font-bold",
                      )}
                      id="authorize"
                    />
                    <Label htmlFor="authorize" className="cursor-pointer">
                      Autorización de documento
                    </Label>
                  </div>
                </div>
              </div>
            </RadioGroup>
          </Field>

          <Field label="¿Protocolizar?">
            <div
              className={`flex flex-col md:flex-row md:items-center md:gap-6 md:space-x-3`}
            >
              <p className="text-primary-400 font-bold">(+ 6000 CLP)</p>

              <label
                htmlFor="protocolize"
                className="flex cursor-pointer flex-row items-center gap-3"
              >
                <Checkbox
                  id="protocolize"
                  checked={isProtocolized}
                  onCheckedChange={(e) =>
                    setIsProtocolized(e !== "indeterminate" ? e : false)
                  }
                />
                ¿Protocolizar documento?
              </label>
            </div>
          </Field>
        </Fieldset>
      </CardContent>

      <CardFooter className="flex justify-between">
        <Button variant="destructive" onClick={() => onRemove(document.id)}>
          <FontAwesomeIcon icon={faTrash} className="mr-2" />
          <span className="hidden sm:inline">Eliminar</span>
        </Button>

        <Button type="submit" variant="default" className="w-full sm:w-auto">
          <FontAwesomeIcon icon={faSave} className="mr-2" />
          <span>Guardar</span>
        </Button>
      </CardFooter>
    </form>
  );
};

const DocumentItem = ({ document }: { document: NewTransactionDocument }) => {
  const { updateDocument, removeDocument } = useTransactionStore();
  const [isOpen, setIsOpen] = useState(!document.subcategory);

  return (
    <Card>
      <CardTitle className="flex items-center justify-between">
        <div className="flex items-center gap-3 truncate p-4">
          <FontAwesomeIcon
            icon={document.subcategory ? faCircleCheck : faCircleExclamation}
            size="lg"
            className={
              document.subcategory
                ? "text-primary"
                : "text-yellow-500 dark:text-yellow-300"
            }
          />
          <div>{document.title}</div>
        </div>

        <div className={`flex-none items-center border-l  ${isOpen ? "" : ""}`}>
          <button
            type="button"
            className="p-4"
            onClick={() => setIsOpen((prev) => !prev)}
          >
            <FontAwesomeIcon
              className="h-5 w-5"
              icon={isOpen ? faClose : faPencil}
            />
          </button>
        </div>
      </CardTitle>

      {isOpen ? (
        <>
          <Separator className="mb-8" />
          <DocumentItemForm
            document={document}
            onSave={(data) => {
              updateDocument(document.id, data);
              setIsOpen(false);
            }}
            onRemove={(documentId) => removeDocument(documentId)}
          />
        </>
      ) : null}
    </Card>
  );
};

const PickFilesPage = () => {
  const { documents, addDocument } = useTransactionStore();
  const navigate = useNavigate();

  const onDrop = useCallback(async (files: File[]) => {
    for await (const file of files) {
      addDocument({
        id: uuidv4(),
        title: file.name,
        description: null,
        category: null,
        subcategory: null,
        filename: file.name,
        size: file.size,
        signees: [],
        attachments: [],
        contents: file,
        isProtocolized: false,
        procedureType: ProcedureTypeEnum.CERTIFY,
      });
    }
  }, []);

  const onDropRejected = useCallback(
    (fileRejections: FileRejection[]): void => {
      fileRejections.forEach((fileRejection) => {
        toast.error(
          `El archivo ${fileRejection.file.name} no pudo ser agregado. ${
            fileRejection.errors[0].message.startsWith("File is larger than")
              ? "El archivo es demasiado grande."
              : "El archivo no es un PDF."
          }`,
        );
      });
    },
    [],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    accept: {
      "application/pdf": [".pdf"],
    },
    multiple: false,
    maxSize: 10 * 1024 * 1024,
  });

  const hasFiles = documents.length > 0;
  const documentsHasCategories = documents.every((x) => x.subcategory);

  return (
    <div className="flex flex-col gap-4">
      <Heading title="Seleccionar archivos">
        {/* {hasFiles && (
          <Button size="lg" onClick={open} className="py-6">
            <FontAwesomeIcon icon={faCloudUpload} className="mr-3" size="xl" />
            <div>
              <div className="flex flex-col items-start justify-center">
                <h1 className="text-left text-sm">Seleccionar más archivos</h1>
                <p className="text-left text-xs">
                  Tamaño máximo por archivo: 10 MB
                </p>
              </div>
            </div>
          </Button>
        )} */}
      </Heading>

      {hasFiles ? (
        <div className="flex flex-col gap-3">
          {documents.map((item) => (
            <DocumentItem key={item.id} document={item} />
          ))}
        </div>
      ) : null}

      <div
        className={cn(
          "mt-3 w-full cursor-pointer rounded border-2 border-dashed border-slate-400 bg-slate-100 p-6 text-center transition-colors",
          "hover:bg-slate-200 dark:border-slate-700 dark:bg-slate-800 dark:hover:bg-slate-900",
          hasFiles ? "hidden" : "",
        )}
        {...getRootProps()}
      >
        <input {...getInputProps()} type="file" className="hidden" />

        <div className="space-y-3">
          <div>
            <FontAwesomeIcon icon={faCloudUpload} size="2xl" />
          </div>

          <div className="md:hidden">
            Haz click para seleccionar archivos (Solo archivos en formato PDF)
          </div>

          <div className="hidden md:block">
            {isDragActive ? (
              "Suelta aquí el archivo"
            ) : (
              <span>
                Arrastra tus archivos aquí o haz click para seleccionarlos.
                <br /> Solo archivos en formato PDF.
                <br /> Tamaño máximo por archivo: 10MB
              </span>
            )}
          </div>
        </div>
      </div>

      {hasFiles ? (
        <div className="mt-2 md:flex md:justify-end">
          <Button
            className="w-full md:w-auto"
            size="lg"
            disabled={!documentsHasCategories}
            onClick={() => {
              if (documentsHasCategories) {
                const firstDocument = documents[0];
                navigate(`/transactions/new/${firstDocument.id}/add-signees`);
              }
            }}
          >
            <span>Agregar firmantes</span>
            <FontAwesomeIcon icon={faArrowRight} />
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export { PickFilesPage };
