import { useQuery } from "@tanstack/react-query";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { NewTransactionNotary } from "@/stores/TransactionStore";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
} from "@/components/ui/select";

import { type NotaryItem } from "@/api/notaries";
import { Separator } from "@/components/ui/separator";
import { useState } from "react";
import { getNotaries } from "@/api/v2/notaries";
import { ProcedureTypeEnum } from "@/enums/ProcedureType.enum";

const NotarySelectorCard = ({
  notary,
  procedureType,
  onChange,
}: {
  notary: NewTransactionNotary | null;
  procedureType: ProcedureTypeEnum;
  onChange: (notary?: NotaryItem) => void;
}) => {
  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const query = useQuery(["notaries"], getNotaries);

  const notaries = query.data || [];

  const filteredNotaries = notaries.filter((item) => {
    if (
      item.procedures.length === 0 ||
      item.procedures.includes(procedureType)
    ) {
      return true;
    }
  });

  return (
    <Card>
      <CardHeader>Notario</CardHeader>

      <Separator className="mb-6" />

      <CardContent>
        <Select
          onValueChange={(value) => {
            const selection = filteredNotaries.find((x) => x.id === value);
            setSelectedValue(selection?.name ?? null);

            if (selection) {
              onChange(selection);
            } else {
              onChange(undefined);
            }
          }}
          value={notary?.id}
        >
          <SelectTrigger className="w-full md:w-3/12">
            {query.isLoading
              ? "Cargando notarios..."
              : selectedValue
                ? selectedValue
                : "Seleccionar notario"}
          </SelectTrigger>

          <SelectContent>
            <SelectGroup>
              {filteredNotaries?.map((item) => (
                <SelectItem key={item.id} value={item.id}>
                  {item.name}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
      </CardContent>
    </Card>
  );
};

export { NotarySelectorCard };
